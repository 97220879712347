
  import "@fontsource-variable/public-sans";
  import "@fontsource-variable/public-sans/wght-italic.css";
  import "@fontsource-variable/jetbrains-mono";
  import "@fontsource-variable/jetbrains-mono/wght-italic.css";
  import "bootstrap-icons/font/bootstrap-icons.css";
  import "katex/dist/katex.css";
  import "tippy.js/dist/tippy.css";
  import "tippy.js/dist/svg-arrow.css";
  import "tippy.js/dist/border.css";
  import "@leafac/css/static/index.css";
  import "./index.css";

  import autosize from "autosize";
  import Mousetrap from "mousetrap";
  import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
  import * as tippy from "tippy.js";
  import textareaCaret from "textarea-caret";
  import * as textFieldEdit from "text-field-edit";
  import { unified } from "unified";
  import rehypeParse from "rehype-parse";
  import rehypeRemark from "rehype-remark";
  import remarkGfm from "remark-gfm";
  import remarkStringify from "remark-stringify";
  // import * as leafac from "@leafac/javascript/static/index.mjs";
  import * as leafac from "./leafac--javascript.mjs";

  leafac.customFormValidation();
  leafac.warnAboutLosingInputs();
  leafac.tippySetDefaultProps();
  leafac.liveNavigation();
/********************************************************************************/

leafac.execute.functions.set("esiuprnjstllbb", function (event) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Sign up on a Courselore installation managed by the developers of Courselore.",
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("fomhgyheydeoig", function (event) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Sign in on a Courselore installation managed by the developers of Courselore.",
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("abtpffrqjnamc", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Join the Courselore community on Meta Courselore, a Courselore course that isn’t really a course, but a place to talk about Courselore itself.",  
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("caxsoypwnzevte", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Courselore is open source and will be free forever for installing on your own server.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("gejvgbbmrkzxgm", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "A Courselore installation running the latest development version. Not for use with real courses.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("gwglhiuerrsnet", function (event) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        trigger: "click",
                        content: "A straightforward way to make text bold, include links, and so forth.",
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("eosbpprmtycexg", function (event) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        trigger: "click",
                        content: "A way to write mathematical formulas.",
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("gufpgpiajhfwgk", function (event) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        trigger: "click",
                        content: "Color computer code to make it easier to read.",
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("bgyimxunfvfxjj", function (event) {

                this.isModified = false;
              });

/********************************************************************************/

leafac.execute.functions.set("fstbexzbletcma", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Sign up on a Courselore installation managed by the developers of Courselore.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("deirqharjstubp", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Sign in on a Courselore installation managed by the developers of Courselore.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("doqhttaaydmefk", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Join the Courselore community on Meta Courselore, a Courselore course that isn’t really a course, but a place to talk about Courselore itself.",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("vsksmcvsbykxr", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Courselore is open source and will be free forever for installing on your own server.",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("bbnxayksyitxhm", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "A Courselore installation running the latest development version. Not for use with real courses.",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("zyczawlnqudcv", function (event) {

                this.isModified = false;

                this.oninput = () => {
                  const filterPhrases = this.value.split(/[^a-z0-9]+/i).filter((filterPhrase) => filterPhrase.trim() !== "");
                  for (const user of document.querySelectorAll('[key^="user/"]')) {
                    let userHidden = filterPhrases.length > 0;
                    for (const filterablePhrasesElement of user.querySelectorAll("[data-filterable-phrases]")) {
                      const filterablePhrases = JSON.parse(filterablePhrasesElement.getAttribute("data-filterable-phrases"));
                      const filterablePhrasesElementChildren = [];
                      for (const filterablePhrase of filterablePhrases) {
                        let filterablePhraseElement;
                        if (filterPhrases.some(filterPhrase => filterablePhrase.toLowerCase().startsWith(filterPhrase.toLowerCase()))) {
                          filterablePhraseElement = document.createElement("mark");
                          filterablePhraseElement.classList.add("mark");
                          userHidden = false;
                        } else
                          filterablePhraseElement = document.createElement("span");
                        filterablePhraseElement.textContent = filterablePhrase;
                        filterablePhrasesElementChildren.push(filterablePhraseElement);
                      }
                      filterablePhrasesElement.replaceChildren(...filterablePhrasesElementChildren);
                    }
                    user.hidden = userHidden;
                  }
                };
              });

/********************************************************************************/

leafac.execute.functions.set("cnujbosyaaqdcf", function (event) {

                  this.onbeforemorph = (event) => !event?.detail?.liveUpdate;
                });

/********************************************************************************/

leafac.execute.functions.set("jdbrydgeplprj", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              touch: false,
                              content: "Copy Email",
                            },
                          });

                          leafac.setTippy({
                            event,
                            element: this,
                            elementProperty: "copied",
                            tippyProps: {
                              theme: "green",
                              trigger: "manual",
                              content: "Copied",
                            },
                          });

                          this.onclick = async () => {
                            await navigator.clipboard.writeText($$0);
                            this.copied.show();
                            await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                            this.copied.hide();
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("epjpghpduyhztx", function (event) {

                            leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                          });

/********************************************************************************/

leafac.execute.functions.set("dbafywbukijsii", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              touch: false,
                              content: "Update System Role",
                            },
                          });
                          
                          leafac.setTippy({
                            event,
                            element: this,
                            elementProperty: "dropdown",
                            tippyProps: {
                              trigger: "click",
                              interactive: true,
                              content: $$0,  
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("dnlqsmcstnvnwx", function (event) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          theme: "rose",
                                                          trigger: "click",
                                                          content: "You may not update your own role because you’re the only administrator.",
                                                        },
                                                      });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("hhjiysoglyzxwf", function (event, $$0) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        elementProperty: "dropdown",
                                                        tippyProps: {
                                                          theme: "rose",
                                                          trigger: "click",
                                                          interactive: true,
                                                          appendTo: document.querySelector("body"),
                                                          content: $$0,  
                                                        },
                                                      });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("dyoiglowopypyg", function (event) {

                              leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                            });

/********************************************************************************/

leafac.execute.functions.set("gkeedxynglvyft", function (event) {

                                  leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                                });

/********************************************************************************/

leafac.execute.functions.set("btzgovmsiavnys", function (event) {

                  this.isModified = false;
                });

/********************************************************************************/

leafac.execute.functions.set("ejqtnukefrwpfx", function (event) {

                        this.onbeforelivenavigate = () => false;
                      });

/********************************************************************************/

leafac.execute.functions.set("cxtolggtbdeecq", function (event) {

                  this.onvalidate = () => {
                    if (this.value !== this.closest("form").querySelector('[name="password"]').value)
                      return "Password & Password Confirmation don’t match.";
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("esdxmzcqffotnw", function (event) {

                    this.onbeforelivenavigate = () => false;
                  });

/********************************************************************************/

leafac.execute.functions.set("cwuyauxjuvclid", function (event, $$0) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                touch: false,
                content: $$0,  
              },
            });
          });

/********************************************************************************/

leafac.execute.functions.set("cehqbdrtxdsiri", function (event, $$0) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: $$0,
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("gksecezdqnjilg", function (event, $$0) {

              leafac.setTippy({
                event,
                element: this,
                tippyProps: {
                  touch: false,
                  interactive: true,
                  appendTo: document.querySelector("body"),
                  content: $$0,  
                },
              });
            });

/********************************************************************************/

leafac.execute.functions.set("elvrcckyrjckkh", function (event, $$0) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                touch: false,
                interactive: true,
                appendTo: document.querySelector("body"),
                content: $$0,  
              },
            });
          });

/********************************************************************************/

leafac.execute.functions.set("ebjhxcjoygovhp", function (event, $$0) {

                  if ($$0)
                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        touch: false,
                        content: "Your Vote",  
                      },
                    });
                });

/********************************************************************************/

leafac.execute.functions.set("gvvnsnhjaqwlb", function (event, $$0) {

                          this.style.setProperty("--width", $$0);
                        });

/********************************************************************************/

leafac.execute.functions.set("bgtznpkxmpyres", function (event) {

                        leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                      });

/********************************************************************************/

leafac.execute.functions.set("fygkfhtdxkkodz", function (event) {

                              this.onclick = () => {
                                this.closest("form").isValid = true;
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("dnqputwwsbmfbr", function (event) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                trigger: "click",
                                content: "The course staff and the poll creator may see individual votes. Students may see aggregate results.",
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("gkamqhjkkjljer", function (event) {

                                    this.onclick = async () => {
                                      const poll = this.closest('[key="poll--show"]');
                                      for (const element of poll.querySelectorAll('[data-results="true"]:not([data-results-votes="true"])'))
                                        element.hidden = false;
                                      poll.querySelector('[key="poll--show--actions--show-results"]').hidden = true;
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("gzpzhvrtidwuin", function (event) {

                                    this.onclick = async () => {
                                      const poll = this.closest('[key="poll--show"]');
                                      for (const element of poll.querySelectorAll('[data-results="true"]'))
                                        element.hidden = true;
                                      poll.querySelector('[key="poll--show--actions--show-results"]').hidden = false;
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("bcgyauqzsnkqnz", function (event, $$0) {

                          this.onclick = async () => {
                            const poll = this.closest('[key="poll--show"]');
                            const loading = poll.querySelector('[key="poll--show--actions--show-votes--loading"]');
                            loading.hidden = false;
                            const partial = leafac.stringToElement(await (await fetch($$0, { cache: "no-store" })).text());
                            for (const partialElement of partial.querySelectorAll('[key^="poll--show--option--votes/"]')) {
                              const element = poll.querySelector('[key="' + partialElement.getAttribute("key") + '"]');
                              element.onbeforemorph = (event) => !event?.detail?.liveUpdate;
                              leafac.morph(element, partialElement);
                              leafac.execute({ element });
                              element.hidden = false;
                            }
                            loading.hidden = true;
                            poll.querySelector('[key="poll--show--actions--show-votes"]').hidden = true;
                            poll.querySelector('[key="poll--show--actions--hide-votes"]').hidden = false;
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("xvelukigjqead", function (event) {

                          this.onclick = async () => {
                            const poll = this.closest('[key="poll--show"]');
                            for (const element of poll.querySelectorAll('[key^="poll--show--option--votes/"]'))
                              element.hidden = true;
                            poll.querySelector('[key="poll--show--actions--show-votes"]').hidden = false;
                            poll.querySelector('[key="poll--show--actions--hide-votes"]').hidden = true;
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("hbfszpmqhhnbdl", function (event, $$0) {

                          this.onclick = async () => {
                            const edit = this.closest('[key^="poll/"]').querySelector('[key="poll--edit"]');
                            const loading = this.querySelector('[key="loading"]');
                            loading.hidden = false;
                            edit.onbeforemorph = (event) => !event?.detail?.liveUpdate;
                            leafac.morph(edit, await (await fetch($$0, { cache: "no-store" })).text());
                            loading.hidden = true;
                            leafac.execute({ element: edit });
                            this.closest('[key^="poll/"]').querySelector('[key="poll--show"]').hidden = true;
                            edit.hidden = false;
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("gyizffpjgaolrp", function (event) {

                          this.onclick = () => {
                            this.closest("form").isValid = true;
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("fqipjrjpuwmofy", function (event) {

        this.onbeforemorph = (event) => !event?.detail?.liveUpdate;
      });

/********************************************************************************/

leafac.execute.functions.set("dyseiaggjnxfyf", function (event) {

                  this.isModified = false;

                  this.onclick = () => {
                    this.closest('[key="content-editor"]').querySelector('[key="content-editor--write"]').hidden = false;
                    this.closest('[key="content-editor"]').querySelector('[key="content-editor--loading"]').hidden = true;
                    this.closest('[key="content-editor"]').querySelector('[key="content-editor--preview"]').hidden = true;  
                    this.closest('[key="content-editor"]').querySelector('[key="content-editor--toolbar--write"]').hidden = false;
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("xckzixkklusyc", function (event, $$0) {

                  this.isModified = false;

                  this.onclick = async (event) => {
                    const write = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write"]');
                    const loading = this.closest('[key="content-editor"]').querySelector('[key="content-editor--loading"]');
                    const preview = this.closest('[key="content-editor"]').querySelector('[key="content-editor--preview"]');
                    const toolbar = this.closest('[key="content-editor"]').querySelector('[key="content-editor--toolbar--write"]');
                    const textarea = write.querySelector("textarea");
                    const previousTextareaRequired = textarea.required;
                    textarea.required = true;
                    const isWriteValid = leafac.validate(write);
                    textarea.required = previousTextareaRequired;
                    if (!isWriteValid) {
                      event.preventDefault();
                      return;
                    }
                    tippy.hideAll();
                    write.hidden = true;
                    loading.hidden = false;
                    preview.hidden = true;
                    toolbar.hidden = true;
                    leafac.loadPartial(
                      preview,
                      await (
                        await fetch($$0, {
                          method: "POST",
                          headers: { "CSRF-Protection": "true", },
                          cache: "no-store",
                          body: new URLSearchParams({ content: textarea.value, }),
                        })
                      ).text()
                    );
                    write.hidden = true;
                    loading.hidden = true;
                    preview.hidden = false;
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("facmejvuklbwds", function (event, $$0) {

                  leafac.setTippy({
                    event,
                    element: this,
                    tippyProps: {
                      touch: false,
                      content: $$0,  
                    },
                  });

                  const textarea = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');

                  (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+p", () => { this.click(); return false; });
                });

/********************************************************************************/

leafac.execute.functions.set("fohxlxprcbjisq", function (event) {

                              this.hidden = leafac.isAppleDevice;
                            });

/********************************************************************************/

leafac.execute.functions.set("jvxilfbjtrnkh", function (event) {

                              this.hidden = !leafac.isAppleDevice;
                            });

/********************************************************************************/

leafac.execute.functions.set("fmqrtryuggygem", function (event, $$0) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Headings",
                  },
                });

                leafac.setTippy({
                  event,
                  element: this,
                  elementProperty: "dropdown",
                  tippyProps: {
                    trigger: "click",
                    interactive: true,
                    content: $$0,  
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("ehyrhsugzfteej", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "# ", "HEADING\n\n");
                                  textarea.selectionEnd += "HEADING".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "# ", "\n\n");
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+1", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("xjocgfayroegh", function (event) {

                                  this.hidden = leafac.isAppleDevice;
                                });

/********************************************************************************/

leafac.execute.functions.set("ddkrwleebevugi", function (event) {

                                  this.hidden = !leafac.isAppleDevice;
                                });

/********************************************************************************/

leafac.execute.functions.set("bjjcfxdduflxg", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "## ", "HEADING\n\n");
                                  textarea.selectionEnd += "HEADING".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "## ", "\n\n");
                                textarea.focus();  
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+2", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("gdjxgazekdbpmh", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "### ", "HEADING\n\n");
                                  textarea.selectionEnd += "HEADING".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "### ", "\n\n");
                                textarea.focus();  
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+3", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("bxtbuuqadunpxv", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "---\n\n", "");
                                textarea.focus();  
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("gqdzcnwabjtvnb", function (event, $$0) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Inline",
                  },
                });

                leafac.setTippy({
                  event,
                  element: this,
                  elementProperty: "dropdown",
                  tippyProps: {
                    trigger: "click",
                    interactive: true,
                    content: $$0,  
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("dlxrgosvlakxhr", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "**", "TEXT**");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "**");
                                textarea.focus();
                              };
                        
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+b", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("hkuiirddazqlmj", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "_", "TEXT_");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "_");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+i", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("gzftlqatvjripx", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "<u>", "TEXT</u>");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "<u>", "</u>");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+u", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("ecylwpybigvfeh", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "~~", "TEXT~~");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "~~");
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+s", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("certivjsclgdtg", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "[", "TEXT](https://example.com)");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "[", "](https://example.com)");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+k", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("hfaxvboicnoidw", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "$", "LATEX$");
                                  textarea.selectionEnd += "LATEX".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "$");
                                textarea.focus();
                              };
                            
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+e", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("dvncnpenzpowf", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "`", "CODE`");
                                  textarea.selectionEnd += "CODE".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "`");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+e", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("qjlmknpsaogqc", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "<ins>", "TEXT</ins>");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "<ins>", "</ins>");
                                textarea.focus();
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("eyxcfuhrescobd", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "~~", "TEXT~~");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "~~");
                                textarea.focus();
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("fuikcmtovqceok", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "<sup>", "TEXT</sup>");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "<sup>", "</sup>");
                                textarea.focus();
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("dssbjrxnhowxwx", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "<sub>", "TEXT</sub>");
                                  textarea.selectionEnd += "TEXT".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "<sub>", "</sub>");
                                textarea.focus();
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("bjaeonjhgpfzzn", function (event, $$0) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: $$0,  
                  },
                });

                this.onclick = () => {
                  this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--attachments"]').click();
                };

                const textarea = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');

                (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+k", () => { this.click(); return false; });
              });

/********************************************************************************/

leafac.execute.functions.set("byzjlrqfbuvezb", function (event) {

                            this.hidden = leafac.isAppleDevice;
                          });

/********************************************************************************/

leafac.execute.functions.set("bgsfrsrdlmeekc", function (event) {

                            this.hidden = !leafac.isAppleDevice;
                          });

/********************************************************************************/

leafac.execute.functions.set("cclanjnavopzlb", function (event, $$0, $$1, $$2) {

                this.isModified = false;

                const textarea = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');

                this.upload = async (fileList) => {
                  if (!checkIsSignedIn()) return;
                  const body = new FormData();
                  for (const file of fileList) body.append("attachments", file);
                  this.value = "";
                  tippy.hideAll();
                  textarea.uploadingIndicator.show();
                  textarea.disabled = true;
                  const response = await (await fetch($$0, {
                    method: "POST",
                    headers: { "CSRF-Protection": "true", },
                    cache: "no-store",
                    body,
                  })).text();
                  textarea.disabled = false;
                  textarea.uploadingIndicator.hide();
                  textFieldEdit.wrapSelection(textarea, response, "");
                  textarea.focus();
                };

                const checkIsSignedIn = (() => {
                  if ($$1) {
                    leafac.setTippy({
                      event,
                      element: textarea,
                      tippyProps: {
                        trigger: "manual",
                        theme: "rose",
                        content: "You must sign in to upload files.",
                      },
                    });

                    return () => {
                      textarea.tooltip.show();
                      return false;
                    };
                  } else
                    return () => true;
                })();

                leafac.setTippy({
                  event,
                  element: textarea,
                  elementProperty: "uploadingIndicator",
                  tippyProps: {
                    trigger: "manual",
                    hideOnClick: false,
                    content: $$2,  
                  },
                });

                this.onclick = (event) => {
                  if (!checkIsSignedIn()) event.preventDefault();
                };

                this.onchange = () => {
                  this.upload(this.files);
                };
              });

/********************************************************************************/

leafac.execute.functions.set("eivbwvgxsowybz", function (event, $$0) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Block",
                  },
                });

                leafac.setTippy({
                  event,
                  element: this,
                  elementProperty: "dropdown",
                  tippyProps: {
                    trigger: "click",
                    interactive: true,
                    content: $$0,  
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("hljynlpodxtuj", function (event, $$0) {

                                    this.onclick = () => {
                                      tippy.hideAll();
                                      const write = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write"]');
                                      if (write.querySelector('[key="poll-editor"]') !== null) return;
                                      const poll = leafac.stringToElement($$0).querySelector('[key="poll-editor"]');
                                      write.insertAdjacentElement("afterbegin", poll);
                                      leafac.execute({ element: poll });
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("dmzxespaoycteh", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "- ", "TEXT\n\n");
                                  textarea.selectionEnd += "TEXT".length;
                                } else {
                                  const replacement = textFieldEdit.getSelection(textarea).split("\n").map((line) => "- " + line).join("\n");
                                  const selectionStart = textarea.selectionStart + ((textarea.selectionStart > 0) ? "\n\n" : "").length;
                                  textFieldEdit.insert(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + replacement + "\n\n");
                                  textarea.selectionStart = selectionStart;
                                  textarea.selectionEnd = textarea.selectionStart + replacement.length;
                                }
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+8", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("ekhgzyigxjdmj", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "1. ", "TEXT\n\n");
                                  textarea.selectionEnd += "TEXT".length;
                                } else {
                                  const replacement = textFieldEdit.getSelection(textarea).split("\n").map((line, index) => String(index + 1) + ". " + line).join("\n");
                                  const selectionStart = textarea.selectionStart + ((textarea.selectionStart > 0) ? "\n\n" : "").length;
                                  textFieldEdit.insert(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + replacement + "\n\n");
                                  textarea.selectionStart = selectionStart;
                                  textarea.selectionEnd = textarea.selectionStart + replacement.length;
                                }
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+7", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("yhpblaoamkiem", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "- [ ] ", "TEXT (USE [X] TO MARK ITEM AS DONE)\n\n");
                                  textarea.selectionEnd += "TEXT (USE [X] TO MARK ITEM AS DONE)".length;
                                } else {
                                  const replacement = textFieldEdit.getSelection(textarea).split("\n").map((line) => "- [ ] " + line).join("\n");
                                  const selectionStart = textarea.selectionStart + ((textarea.selectionStart > 0) ? "\n\n" : "").length;
                                  textFieldEdit.insert(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + replacement + "\n\n");
                                  textarea.selectionStart = selectionStart;
                                  textarea.selectionEnd = textarea.selectionStart + replacement.length;
                                }
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+9", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("gfstjluttsgqjn", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "> ", "TEXT\n\n");
                                  textarea.selectionEnd += "TEXT".length;
                                } else {
                                  const replacement = textFieldEdit.getSelection(textarea).split("\n").map((line) => "> " + line).join("\n");
                                  const selectionStart = textarea.selectionStart + ((textarea.selectionStart > 0) ? "\n\n" : "").length;
                                  textFieldEdit.insert(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + replacement + "\n\n");
                                  textarea.selectionStart = selectionStart;
                                  textarea.selectionEnd = textarea.selectionStart + replacement.length;
                                }
                                textarea.focus();
                              };

                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+'", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("glqbesfgukvuni", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                const selectionStart = textarea.selectionStart + (textFieldEdit.getSelection(textarea) + ((textarea.selectionEnd > 0) ? "\n\n" : "") + "| ").length;
                                textFieldEdit.insert(textarea, textFieldEdit.getSelection(textarea) + ((textarea.selectionEnd > 0) ? "\n\n" : "") + "| HEADING | HEADING |\n|---------|---------|\n| CONTENT | CONTENT |\n\n");
                                textarea.selectionStart = selectionStart;
                                textarea.selectionEnd = textarea.selectionStart + "HEADING".length;
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+t", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("bynzrnmsgneqtt", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "<details>\n<summary>", "SUMMARY</summary>\n\nCONTENT\n\n</details>\n\n");
                                  textarea.selectionEnd += "SUMMARY".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "<details>\n<summary>SUMMARY</summary>\n\n", "\n\n</details>\n\n");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+d", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("gtqhysznbofwwg", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                const identifier = Math.random().toString(36).slice(2);
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, "[^footnote--" + identifier + "]\n\n[^footnote--" + identifier + "]: ", "FOOTNOTE");
                                  textarea.selectionEnd += "FOOTNOTE".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, "[^footnote--" + identifier + "]\n\n[^footnote--" + identifier + "]: ", "");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+f", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("yunfshwguayeg", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "$$\n", "LATEX\n$$\n\n");
                                  textarea.selectionEnd += "LATEX".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "$$\n", "\n$$\n\n");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+alt+shift+e", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("dffmlznzmizqby", function (event) {

                              const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                              this.onclick = () => {
                                this.closest("[data-tippy-root]")._tippy.hide();
                                if (textarea.selectionStart === textarea.selectionEnd) {
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "```", "LANGUAGE\nCODE\n```\n\n");
                                  textarea.selectionEnd += "LANGUAGE".length;
                                }
                                else
                                  textFieldEdit.wrapSelection(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + "```LANGUAGE\n", "\n```\n\n");
                                textarea.focus();
                              };
                          
                              (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+shift+e", () => { this.click(); return false; });
                            });

/********************************************************************************/

leafac.execute.functions.set("bvfcojuanyzxdq", function (event, $$0) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Mentions & References",
                        },
                      });

                      leafac.setTippy({
                        event,
                        element: this,
                        elementProperty: "dropdown",
                        tippyProps: {
                          trigger: "click",
                          interactive: true,
                          content: $$0,  
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("cmamorcwetbgqw", function (event) {

                                    const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
                  
                                    this.onclick = () => {
                                      this.closest("[data-tippy-root]")._tippy.hide();
                                      textFieldEdit.wrapSelection(textarea, " @", "");
                                      textarea.focus();
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("bupywzticrrorp", function (event) {

                                    const textarea = this.closest("[data-tippy-root]")._tippy.reference.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
                  
                                    this.onclick = () => {
                                      this.closest("[data-tippy-root]")._tippy.hide();
                                      textFieldEdit.wrapSelection(textarea, " #", "");
                                      textarea.focus();
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("eqydkqzyotnzqv", function (event, $$0) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: $$0,
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("psiflgocnvuva", function (event, $$0) {

                  this.isModified = false;
            
                  const textarea = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');
            
                  this.onchange = async () => {
                    textarea.classList[this.checked ? "add" : "remove"]("content-editor--write--textarea--programmer-mode");
                    await fetch($$0, {
                      method: "PATCH",
                      headers: { "CSRF-Protection": "true", },
                      cache: "no-store",
                      body: new URLSearchParams({ preferContentEditorProgrammerMode: String(this.checked), }),
                    });
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("btffumqyrqvhro", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Help",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("csmmcoppnakcqh", function (event, $$0, $$1, $$2, $$3, $$4, $$5, $$6, $$7, $$8) {

                      if ($$0) this.isModified = false;

                      autosize(this);
                      autosize.update(this);
      
                      this.ondragenter = (event) => {
                        if (!event.dataTransfer.types.includes("Files")) return;
                        event.preventDefault();
                        this.classList.add("drag");
                      };
                      this.ondragleave = (event) => {
                        if (!event.dataTransfer.types.includes("Files")) return;
                        event.preventDefault();
                        this.classList.remove("drag");
                      };
                      this.ondragover = (event) => {
                        if (!event.dataTransfer.types.includes("Files")) return;
                        event.preventDefault();
                      };
                      this.ondrop = (event) => {
                        if (!event.dataTransfer.types.includes("Files")) return;
                        event.preventDefault();
                        this.classList.remove("drag");
                        const fileList = [...event.dataTransfer.items].flatMap((item) => item.webkitGetAsEntry()?.isFile ?? true ? [item.getAsFile()] : []);
                        if (fileList.length > 0)
                          this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--attachments"]').upload(fileList);
                      };
      
                      this.onpaste = (event) => {
                        window.setTimeout(() => {
                          this.closest('[key="content-editor"]').scrollIntoView();
                        });

                        this.closest('[key="content-editor"]').querySelector('[key="content-editor--rich-text-help"]').hidden = true;

                        if (leafac.shiftKey) return;

                        if (event.clipboardData.types.includes("text/html")) {
                          if (!leafac.isPhysicalKeyboard) return;

                          event.preventDefault();

                          const placeholder = "◊◊" + Math.random().toString(36).slice(2) + "◊◊";
                          const replacements = [];

                          const html = leafac.stringToElement(event.clipboardData.getData("text/html"));

                          for (const element of html.querySelectorAll(".katex, .katex-display")) {
                            const annotation = element.querySelector('annotation[encoding="application/x-tex"]');
                            if (annotation === null) continue;
                            replacements.push(
                              (element.matches(".katex-display") ? "\n\n$$\n" : "$") +
                              annotation.textContent +
                              (element.matches(".katex-display") ? "\n$$\n\n" : "$")
                            );
                            element.outerHTML = "<span>" + placeholder + "</span>";
                          }

                          textFieldEdit.insert(
                            this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]'),
                            unified()
                              .use(rehypeParse, { fragment: true })
                              .use(rehypeRemark)
                              .use(remarkGfm, { singleTilde: false })
                              .use(remarkStringify)
                              .processSync(html.innerHTML)
                              .toString()
                              .replaceAll(placeholder, () => replacements.shift())
                          );

                          const richTextHelp = this.closest('[key="content-editor"]').querySelector('[key="content-editor--rich-text-help"]');
                          richTextHelp.hidden = false;
                          window.clearTimeout(richTextHelp.hideTimeout);
                          richTextHelp.hideTimeout = window.setTimeout(() => {
                            richTextHelp.hidden = true;
                          }, 10 * 1000);
                        } else if (event.clipboardData.types.includes("Files")) {
                          event.preventDefault();
                          const fileList = [...event.clipboardData.items].flatMap((item) => item.webkitGetAsEntry()?.isFile ?? true ? [item.getAsFile()] : []);
                          if (fileList.length > 0)
                            this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--attachments"]').upload(fileList);
                        }
                      };
      
                      if ($$1) {
                        const dropdownMenuTarget = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea--dropdown-menu-target"]');
      
                        const dropdownMenus = [
                          {
                            trigger: "@",
                            route: $$2,
                            dropdownMenu: leafac.setTippy({
                              event,
                              element: dropdownMenuTarget,
                              elementProperty: "dropdownMenuMention",
                              tippyProps: {
                                placement: "bottom-start",
                                trigger: "manual",
                                interactive: true,
                                onHidden: (dropdown) => {
                                  leafac.loadPartial(
                                    dropdown.props.content.querySelector('[key="search-results"]'),
                                    $$3
                                  );
                                },
                                content: $$4,  
                              },
                            }),
                          },
                          {
                            trigger: "#",
                            route: $$5,
                            dropdownMenu: leafac.setTippy({
                              event,
                              element: dropdownMenuTarget,
                              elementProperty: "dropdownMenuReference",
                              tippyProps: {
                                placement: "bottom-start",
                                trigger: "manual",
                                interactive: true,
                                onHidden: (dropdown) => {
                                  leafac.loadPartial(
                                    dropdown.props.content.querySelector('[key="search-results"]'),
                                    $$6
                                  );
                                },
                                content: $$7,  
                              },
                            }),
                          },
                        ];
      
                        this.isUpdating ??= false;
                        this.shouldUpdateAgain ??= false;
                        this.oninput = async () => {
                          for (const { trigger, route, dropdownMenu } of dropdownMenus) {
                            if (!dropdownMenu.state.isShown) {
                              if (
                                (this.selectionStart > 1 && this.value[this.selectionStart - 2].match(/^\s$/) === null) ||
                                this.value[this.selectionStart - 1] !== trigger
                              ) continue;
                              this.anchorIndex = this.selectionStart;
                              const caretCoordinates = textareaCaret(this, this.selectionStart);
                              dropdownMenuTarget.style.top = String(caretCoordinates.top - this.scrollTop) + "px";
                              dropdownMenuTarget.style.left = String(caretCoordinates.left - 14) + "px";
                              tippy.hideAll();
                              dropdownMenu.show();
                            }
                            const search = this.value.slice(this.anchorIndex, this.selectionStart);
                            if (this.selectionStart < this.anchorIndex || search.match(/[^a-z0-9\/]/i) !== null) {
                              dropdownMenu.hide();
                              continue;
                            }
                            if (this.isUpdating) {
                              this.shouldUpdateAgain = true;
                              continue;
                            }
                            this.isUpdating = true;
                            this.shouldUpdateAgain = false;
                            const content = dropdownMenu.props.content;
                            leafac.loadPartial(
                              content.querySelector('[key="search-results"]'),
                              await (await fetch(route + "?" + new URLSearchParams({ search }), { cache: "no-store" })).text()
                            );
                            const buttons = content.querySelectorAll(".button");
                            for (const button of buttons) button.classList.remove("hover");
                            if (buttons.length > 0) buttons[0].classList.add("hover");
                            this.isUpdating = false;
                            if (this.shouldUpdateAgain) this.oninput();
                          }
                        };
      
                        this.onkeydown = (event) => {
                          for (const { dropdownMenu } of dropdownMenus) {
                            if (!dropdownMenu.state.isShown) continue;
                            const content = dropdownMenu.props.content;
                            switch (event.code) {
                              case "ArrowUp":
                              case "ArrowDown":
                                event.preventDefault();
                                const buttons = [...content.querySelectorAll(".button")];
                                if (buttons.length === 0) continue;    
                                const currentHoverIndex = buttons.indexOf(content.querySelector(".button.hover"));
                                if (
                                  currentHoverIndex === -1 ||
                                  (event.code === "ArrowUp" && currentHoverIndex === 0) ||
                                  (event.code === "ArrowDown" && currentHoverIndex === buttons.length - 1)
                                ) continue;
                                buttons[currentHoverIndex].classList.remove("hover");
                                const buttonToHover = buttons[currentHoverIndex + (event.code === "ArrowUp" ? -1 : 1)];
                                buttonToHover.classList.add("hover");
                                scrollIntoViewIfNeeded(buttonToHover, { scrollMode: "if-needed" });
                                break;
                              case "Enter":
                              case "Tab":
                                event.preventDefault();
                                const buttonHover = content.querySelector(".button.hover");
                                if (buttonHover === null) dropdownMenu.hide();
                                else {
                                  event.preventDefault();
                                  buttonHover.click();
                                }
                                break;
                              case "Escape":
                              case "ArrowLeft":
                              case "ArrowRight":
                              case "Home":
                              case "End":
                                dropdownMenu.hide();
                                break;
                            }
                          }
                        };
      
                        this.dropdownMenuComplete = (text) => {
                          tippy.hideAll();
                          this.setSelectionRange(this.anchorIndex, this.selectionStart);
                          textFieldEdit.insert(this, text);
                          this.focus();
                        };

                        this.onclick = this.onkeyup = () => {
                          if (this.closest('[key="content-editor"]').querySelector('[key="poll-editor"]') !== null) return;
                          
                          for (const match of this.value.matchAll(/<courselore-poll\s+reference="(?<pollReference>\d+)"><\/courselore-poll>/g))
                            if (match.index <= this.selectionStart && this.selectionStart <= match.index + match[0].length) {
                              const caretCoordinates = textareaCaret(this, this.selectionStart);
                              dropdownMenuTarget.style.top = String(caretCoordinates.top - this.scrollTop) + "px";
                              dropdownMenuTarget.style.left = String(caretCoordinates.left) + "px";

                              (window.locals ??= {}).editPollReference = match.groups.pollReference;

                              leafac.setTippy({
                                element: dropdownMenuTarget,
                                elementProperty: "dropdownPollEdit",
                                tippyProps: {
                                  trigger: "manual",
                                  interactive: true,
                                  content: $$8,  
                                },
                              });

                              tippy.hideAll();
                              dropdownMenuTarget.dropdownPollEdit.show();

                              return;
                            }

                          dropdownMenuTarget.dropdownPollEdit?.hide?.();
                        };
                      }
                    });

/********************************************************************************/

leafac.execute.functions.set("xjigshzovofoo", function (event, $$0, $$1) {

                                          this.onclick = async () => {
                                            const loading = this.querySelector('[key="loading"]');
                                            loading.hidden = false;
                                            const response = await fetch($$0 + window.locals.editPollReference + $$1, { cache: "no-store" });
                                            loading.hidden = true;
                                            if (!response.ok) {
                                              leafac.setTippy({
                                                element: this,
                                                elementProperty: "errorTooltip",
                                                tippyProps: {
                                                  theme: "rose",
                                                  trigger: "manual",
                                                  content: await response.text(),
                                                },
                                              });
                                              this.errorTooltip.show();
                                              return;
                                            }
                                            const poll = leafac.stringToElement(await response.text()).querySelector('[key="poll-editor"]');
                                            this.closest('[key="content-editor"]').querySelector('[key="content-editor--write"]').insertAdjacentElement("afterbegin", poll);
                                            leafac.execute({ element: poll });
                                            tippy.hideAll();
                                          };
                                        });

/********************************************************************************/

leafac.execute.functions.set("mjctjpydscnjb", function (event, $$0) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: "Toolbar",
                          },
                        });

                        this.onclick = async () => {
                          const toolbar = this.closest('[key="content-editor"]').querySelector('[key="content-editor--toolbar"]');
                          toolbar.hidden = !toolbar.hidden;
                          await fetch($$0, {
                            method: "PATCH",
                            headers: { "CSRF-Protection": "true", },
                            cache: "no-store",
                            body: new URLSearchParams({ preferContentEditorToolbarInCompact: String(!toolbar.hidden), }),
                          });
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("dhyefjsdptkwkh", function (event) {

              this.hidden = leafac.isAppleDevice;
            });

/********************************************************************************/

leafac.execute.functions.set("egzotlclqisjog", function (event) {

              this.hidden = !leafac.isAppleDevice || leafac.isSafari;
            });

/********************************************************************************/

leafac.execute.functions.set("eayrnwwbutfkok", function (event) {

              this.hidden = !leafac.isAppleDevice || !leafac.isSafari;
            });

/********************************************************************************/

leafac.execute.functions.set("gtgpfsftryymxy", function (event) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                touch: false,
                content: "Drag to Reorder",
              },
            });
          });

/********************************************************************************/

leafac.execute.functions.set("chhwjcsqwdodii", function (event) {

                  this.isModified = true;
                });

/********************************************************************************/

leafac.execute.functions.set("cgfcsrrfdoeyou", function (event) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                theme: "rose",
                touch: false,
                content: "Remove Option",
              },
            });

            this.onclick = () => {
              const options = this.closest('[key="poll-editor--options"]');
              this.closest('[key^="poll-editor--option/"]').remove();
              options.reorder();
            };
          });

/********************************************************************************/

leafac.execute.functions.set("fmtbsuybrhczbt", function (event) {

                        this.onchange = () => {
                          const closesAt = this.closest('[key="poll-editor"]').querySelector('[key="poll-editor--closes-at"]');
                          closesAt.hidden = !this.checked;
                          for (const element of leafac.descendants(closesAt))
                            if (element.disabled !== undefined) element.disabled = !this.checked;
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("csrfiwxfndjaaq", function (event) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: "Set as Closing",
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("gbmirgzmzjxjxg", function (event) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: "Set as Not Closing",
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("gtyropbwqlabsa", function (event, $$0) {

                        this.value = this.defaultValue = leafac.localizeDateTime(this.defaultValue);

                        this.onvalidate = () => {
                          const mustBeInTheFuture = $$0 || leafac.isModified(this);
                          const error = leafac.validateLocalizedDateTime(this);
                          if (typeof error === "string") return error;
                          if (mustBeInTheFuture && new Date(this.value).getTime() <= Date.now()) return "Must be in the future.";
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("bbbggmjwqmzrnd", function (event) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            trigger: "click",
                            content: "This datetime will be converted to UTC, which may lead to surprising off-by-one-hour differences if it crosses a daylight saving time change.",
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("bqyhskdopbxzqb", function (event) {

                    this.onbeforemorph = (event) => {
                      const liveUpdate = event?.detail?.liveUpdate;
                      if (!liveUpdate) this.isModified = false;
                      return !liveUpdate;
                    };

                    this.onpointerdown = (event) => {
                      if (event.target.closest('[key="poll-editor--option--grab--handle"]') === null) return;

                      const body = document.querySelector("body");
                      const option = event.target.closest('[key^="poll-editor--option/"]');

                      this.grabbed = option;
                      body.classList.add("grabbing");
                      option.classList.add("grabbed");

                      body.addEventListener("pointerup", () => {
                        delete this.grabbed;
                        body.classList.remove("grabbing");
                        option.classList.remove("grabbed");
                      }, { once: true });
                    };

                    this.onpointermove = (event) => {
                      const option = (
                        event.pointerType === "touch" ? document.elementFromPoint(event.clientX, event.clientY) : event.target
                      ).closest('[key^="poll-editor--option/"]');
                      if (option === null || [undefined, option].includes(this.grabbed)) return;

                      const boundingClientRect = option.getBoundingClientRect();
                      option[
                        (event.clientY - boundingClientRect.top) / (boundingClientRect.bottom - boundingClientRect.top) < 0.5 ?
                        "after" : "before"
                      ](this.grabbed);
                      this.reorder();
                    };

                    this.onkeydown = (event) => {
                      if (event.target.closest('[key="poll-editor--option--grab--handle"]') === null) return;

                      const option = event.target.closest('[key^="poll-editor--option/"]');
                      switch (event.code) {
                        case "ArrowUp":
                          event.preventDefault();
                          option.previousElementSibling?.before?.(option);
                          break;
                        case "ArrowDown":
                          event.preventDefault();
                          option.nextElementSibling?.after?.(option);
                          break;
                      }
                      option.querySelector('[key="poll-editor--option--grab--handle"]').focus();
                      this.reorder();
                    };

                    this.reorder = () => {
                      this.isModified = true;

                      for (const [order, option] of this.querySelectorAll('[key^="poll-editor--option/"]').entries())
                        for (const element of option.querySelectorAll('[name^="options["]'))
                          element.setAttribute("name", element.getAttribute("name").replace(/\d+/, String(order)));
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("exwpsspzzwyyrt", function (event, $$0, $$1) {

                      this.onclick = () => {
                        const newOption = leafac.stringToElement($$0).querySelector('[key="poll-editor--option/new"]');

                        const options = this.closest('[key="poll-editor"]').querySelector('[key="poll-editor--options"]');
                        options.insertAdjacentElement("beforeend", newOption);
                        leafac.execute({ element: newOption });
                        options.reorder();
                      };

                      if ($$1 && !event?.detail?.liveUpdate)
                        for (const initialOptions of new Array(3))
                          this.onclick();

                      this.onvalidate = () => {
                        if (this.closest("[hidden]") === null && this.closest('[key="poll-editor"]').querySelector('[key="poll-editor--options"]').children.length <= 1)
                          return "Please add at least two options.";
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("gbdntyzeftbwir", function (event, $$0, $$1, $$2, $$3) {

                  if (this.closest('[key^="poll/"]') !== null) return;
                  
                  this.setAttribute("type", "button");

                  this.onclick = async () => {
                    const pollEditor = this.closest('[key="poll-editor"]');
                    const textarea = this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]');

                    if (!leafac.validate(pollEditor)) return;

                    const body = leafac.serialize(pollEditor);

                    leafac.morph(pollEditor.querySelector('[key="poll-editor--content"]'), $$0);

                    const content = await (await fetch($$1, {
                      method: $$2,
                      headers: { "CSRF-Protection": "true", },
                      cache: "no-store",
                      body,
                    })).text();

                    pollEditor.remove();

                    if ($$3)
                      textFieldEdit.insert(textarea, ((textarea.selectionStart > 0) ? "\n\n" : "") + content + "\n\n");
                    textarea.focus();
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("heimpcszlmschn", function (event) {

                  leafac.setTippy({
                    event,
                    element: this,
                    tippyProps: {
                      trigger: "click",
                      content: "The course staff and the poll creator may see individual votes. Students may see aggregate results.",
                    },
                  });
                });

/********************************************************************************/

leafac.execute.functions.set("cmehjvrjwpzuhx", function (event) {

                        this.onclick = () => {
                          this.closest("form").isValid = true;
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("bnmtlcadhhdzys", function (event) {

                  this.onclick = () => {
                    const poll = this.closest('[key^="poll/"]');
                    if (poll !== null) {
                      poll.querySelector('[key="poll--show"]').hidden = false;
                      poll.querySelector('[key="poll--edit"]').hidden = true;
                    }
                    this.closest('[key="poll-editor"]').remove();
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("dcwlqygbczyvjo", function (event, $$0) {

                this.onclick = () => {
                  this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]').dropdownMenuComplete($$0);
                };
              });

/********************************************************************************/

leafac.execute.functions.set("feceudlgdbmhfy", function (event, $$0) {

              this.onclick = () => {
                this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]').dropdownMenuComplete($$0);
              };
          });

/********************************************************************************/

leafac.execute.functions.set("drptlmiszhqasm", function (event, $$0) {

                  this.onclick = () => {
                    this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]').dropdownMenuComplete($$0);
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("hwnqazbnrwqmf", function (event, $$0) {

                    this.onclick = () => {
                      this.closest('[key="content-editor"]').querySelector('[key="content-editor--write--textarea"]').dropdownMenuComplete($$0);
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("gfuqkssrfzptiq", function (event) {

                            this.isModified = false;

                            this.onchange = () => {
                              const searchAndFilters = this.closest('[key="search-and-filters"]');
                              const searchAndFiltersForm = searchAndFilters.querySelector('[key="search-and-filters--form"]');
                              const searchAndFiltersFormSection = searchAndFiltersForm.querySelector('[key="search"]');
                              searchAndFiltersForm.hidden = [...searchAndFilters.querySelectorAll('[key="search-and-filters--show-hide--search"], [key="search-and-filters--show-hide--filters"]')]
                                .every((element) => !element.checked);
                              searchAndFiltersFormSection.hidden = !this.checked;
                              for (const element of leafac.descendants(searchAndFiltersFormSection))
                                if (element.disabled !== undefined) element.disabled = !this.checked;
                              if (this.checked)
                                searchAndFiltersFormSection.querySelector('[name="conversations[search]"]').focus();
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("gjstnnbucuabov", function (event) {

                            this.isModified = false;
                            
                            this.onchange = () => {
                              const searchAndFilters = this.closest('[key="search-and-filters"]');
                              const searchAndFiltersForm = searchAndFilters.querySelector('[key="search-and-filters--form"]');
                              const searchAndFiltersFormSection = searchAndFiltersForm.querySelector('[key="filters"]');
                              searchAndFiltersForm.hidden = [...searchAndFilters.querySelectorAll('[key="search-and-filters--show-hide--search"], [key="search-and-filters--show-hide--filters"]')]
                                .every((element) => !element.checked);
                              searchAndFiltersFormSection.hidden = !this.checked;
                              for (const element of leafac.descendants(searchAndFiltersFormSection))
                                if (element.disabled !== undefined) element.disabled = !this.checked;
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("femqpwqrbwiqsx", function (event) {

                        this.isModified = false;
                      });

/********************************************************************************/

leafac.execute.functions.set("dbjapxrflgnmgw", function (event) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Search",
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("ekehlzhvuftapk", function (event) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      touch: false,
                                      content: "Clear Search",
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("bjwfokvcuwhfdb", function (event) {

                                  this.onchange = () => {
                                    if (this.checked) this.closest("form").querySelector('[name="conversations[filters][isUnread]"][value="false"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("ebgvhynffhsxak", function (event) {

                                  this.onchange = () => {
                                    if (this.checked) this.closest("form").querySelector('[name="conversations[filters][isUnread]"][value="true"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("dypnwkufmxvvgr", function (event, $$0, $$1) {

                                      if ($$0)
                                        this.onchange = () => {
                                          if (this.checked) return;
                                          for (const element of this.closest("form").querySelectorAll($$1))
                                            element.checked = false;
                                        };
                                    });

/********************************************************************************/

leafac.execute.functions.set("dbkxmrgdeplayl", function (event) {

                                  this.onchange = () => {
                                    if (!this.checked) return;
                                    const form = this.closest("form");
                                    form.querySelector('[name="conversations[filters][types][]"][value="question"]').checked = true;
                                    form.querySelector('[name="conversations[filters][isResolved]"][value="true"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("gcogcfyviouqyy", function (event) {

                                  this.onchange = () => {
                                    if (!this.checked) return;
                                    const form = this.closest("form");
                                    form.querySelector('[name="conversations[filters][types][]"][value="question"]').checked = true;
                                    form.querySelector('[name="conversations[filters][isResolved]"][value="false"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("ezwwyzqtfydlpl", function (event) {

                                  this.onchange = () => {
                                    if (!this.checked) return;
                                    const form = this.closest("form");
                                    form.querySelector('[name="conversations[filters][types][]"][value="note"]').checked = true;
                                    form.querySelector('[name="conversations[filters][isAnnouncement]"][value="true"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("dfpctuqfdpxduh", function (event) {

                                  this.onchange = () => {
                                    if (!this.checked) return;
                                    const form = this.closest("form");
                                    form.querySelector('[name="conversations[filters][types][]"][value="note"]').checked = true;
                                    form.querySelector('[name="conversations[filters][isAnnouncement]"][value="false"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("csyvcbcobgwtwk", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    trigger: "click",
                                    content: "Pinned conversations are listed first.",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("cjojxhydfjogox", function (event) {

                                  this.onchange = () => {
                                    if (this.checked) this.closest("form").querySelector('[name="conversations[filters][isPinned]"][value="false"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("efydbcpyknmdkh", function (event) {

                                  this.onchange = () => {
                                    if (this.checked) this.closest("form").querySelector('[name="conversations[filters][isPinned]"][value="true"]').checked = false;
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("fohuijiatglji", function (event) {

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        tippyProps: {
                                          trigger: "click",
                                          content: "Tags help to organize conversations.",
                                        },
                                      });
                                    });

/********************************************************************************/

leafac.execute.functions.set("fatfqywkvpxcwr", function (event) {

                                                  leafac.setTippy({
                                                    event,
                                                    element: this,
                                                    tippyProps: {
                                                      touch: false,
                                                      content: "This tag is visible by the course staff only.",
                                                    },
                                                  });
                                                });

/********************************************************************************/

leafac.execute.functions.set("izesevbcxewwe", function (event, $$0, $$1, $$2) {

                            if ($$0)
                              window.setTimeout(() => {
                                if (event?.detail?.previousLocation?.href?.startsWith($$1)) return;
                                this.querySelector($$2)?.scrollIntoView({ block: "center" });
                              });
                          });

/********************************************************************************/

leafac.execute.functions.set("zdigolwjbjocd", function (event) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Mark as Read",
                                                  },
                                                });
                                                        
                                                this.onclick = async (event) => {
                                                  event.preventDefault();
                                                  event.stopImmediatePropagation();
                                                  await fetch(this.closest("a").getAttribute("href"), { cache: "no-store" });
                                                  this.remove();
                                                };
                                              });

/********************************************************************************/

leafac.execute.functions.set("lbnenfqudyhoy", function (event, $$0, $$1, $$2) {

            if ($$0) {
              leafac.setTippy({
                event,
                element: this,
                tippyProps: {
                  interactive: true,
                  delay: [1000, null],
                  touch: ["hold", 1000],
                  onHidden: () => { this.onmouseleave(); },
                  content: $$1,  
                },
              });

              window.clearTimeout(this.tooltipContentTimeout);
              this.tooltipContentSkipLoading = false;
              
              this.onmouseenter = this.onfocus = async () => {
                window.clearTimeout(this.tooltipContentTimeout);
                if (this.tooltipContentSkipLoading) return;
                this.tooltipContentSkipLoading = true;
                leafac.loadPartial(this.tooltip.props.content.querySelector('[key="content"]'), await (await fetch($$2, { cache: "no-store" })).text());
                this.tooltip.props.content.querySelector('[key="loading"]').hidden = true;
                this.tooltip.props.content.querySelector('[key="content"]').hidden = false;
                this.tooltip.setProps({});
              };
              
              this.onmouseleave = this.onblur = () => {
                window.clearTimeout(this.tooltipContentTimeout);
                if (this.matches(":hover, :focus-within") || this.tooltip.state.isShown) return;
                this.tooltipContentTimeout = window.setTimeout(() => {
                  this.tooltip.props.content.querySelector('[key="loading"]').hidden = false;
                  this.tooltip.props.content.querySelector('[key="content"]').hidden = true;
                  this.tooltipContentSkipLoading = false;
                }, 60 * 1000);
              };
            }
            else
              leafac.setTippy({
                event,
                element: this,
                tippyProps: {
                  touch: false,
                  content: "Participants",
                },
              });
          });

/********************************************************************************/

leafac.execute.functions.set("wlhbriuldkesm", function (event) {

                  leafac.setTippy({
                    event,
                    element: this,
                    tippyProps: {
                      touch: false,
                      content: "Pinned conversations are listed first.",
                    },
                  });
                });

/********************************************************************************/

leafac.execute.functions.set("fsvdsfoqcnbztz", function (event) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                touch: false,
                content: "Conversation Reference",
              },
            });
          });

/********************************************************************************/

leafac.execute.functions.set("dihgzsisrdwxhm", function (event) {

            leafac.relativizeDateTimeElement(this, { capitalize: true });
          });

/********************************************************************************/

leafac.execute.functions.set("fgurcyafrdmtbv", function (event) {

                    leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                  });

/********************************************************************************/

leafac.execute.functions.set("cudhaawkpvdeua", function (event) {

                              leafac.setTippy({
                                event,
                                element: this,
                                tippyProps: {
                                  touch: false,
                                  content: "This tag is visible by the course staff only.",
                                },
                              });
                            });

/********************************************************************************/

leafac.execute.functions.set("ghdutqthnbvmyw", function (event, $$0, $$1, $$2, $$3) {

                            this.onchange = () => {
                              const form = this.closest("form");
                              for (const element of [form.querySelector('[name="content"]'), ...form.querySelectorAll('[name="tagsReferences[]"]')])
                                element.required = $$0;

                              if ($$1) {
                                const notification = form.querySelector('[key="new-conversation--announcement"]');
                                notification.hidden = $$2;
                                for (const element of leafac.descendants(notification))
                                  if (element.disabled !== undefined)
                                    element.disabled = $$3;
                              }
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("celninyzezxjjf", function (event) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                trigger: "click",
                                content: "Tags help to organize conversations.",
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("ffpqrtvqjdwlpn", function (event) {

                                            leafac.setTippy({
                                              event,
                                              element: this,
                                              tippyProps: {
                                                touch: false,
                                                content: "This tag is visible by the course staff only.",
                                              },
                                            });
                                          });

/********************************************************************************/

leafac.execute.functions.set("bgtcivswnjfzsb", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            elementProperty: "dropdown",
                            tippyProps: {
                              trigger: "click",
                              interactive: true,
                              content: $$0,  
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("gqkbwxncththzy", function (event, $$0, $$1, $$2, $$3) {

                                              this.isModified = false;

                                              this.onchange = () => {  
                                                this.closest("form").querySelector($$0).checked = true;

                                                const participantsDropdown = this.closest('[key="participants--dropdown"]');
                                                const selectedParticipants = participantsDropdown.querySelector('[key="participants--dropdown--selected-participants"]');

                                                if ($$1) {
                                                  selectedParticipants.hidden = true;

                                                  for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                    element.disabled = true;
                                                } else if ($$2) {
                                                  selectedParticipants.hidden = false;
                                                  selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--course-staff"]').hidden = false;
                                                  selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--selected-participants"]').hidden = true;

                                                  for (const element of selectedParticipants.querySelectorAll('[data-course-participant-course-role="course-staff"]'))
                                                    element.hidden = true;
                                                  participantsDropdown.querySelector('[key="participants--dropdown--selected-participants--filter"]')?.oninput();

                                                  for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                    element.disabled = element.matches('[data-course-participant-course-role="course-staff"]');

                                                  (this.closest("form").querySelector('[name="isAnnouncement"]') ?? {}).checked = false;
                                                  (this.closest("form").querySelector('[name="isPinned"]') ?? {}).checked = false;
                                                } else if ($$3) {
                                                  selectedParticipants.hidden = false;
                                                  selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--course-staff"]').hidden = true;
                                                  selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--selected-participants"]').hidden = false;

                                                  for (const element of selectedParticipants.querySelectorAll('[data-course-participant-course-role="course-staff"]'))
                                                    element.hidden = false;
                                                  participantsDropdown.querySelector('[key="participants--dropdown--selected-participants--filter"]')?.oninput();

                                                  for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                    element.disabled = false;

                                                  (this.closest("form").querySelector('[name="isAnnouncement"]') ?? {}).checked = false;
                                                  (this.closest("form").querySelector('[name="isPinned"]') ?? {}).checked = false;
                                                }
                                              };
                                            });

/********************************************************************************/

leafac.execute.functions.set("cnpgqwsdniowcr", function (event) {

                                                  this.isModified = false;

                                                  this.oninput = () => {
                                                    const filterPhrases = this.value.split(/[^a-z0-9]+/i).filter((filterPhrase) => filterPhrase.trim() !== "");
                                                    const participantsDropdown = this.closest('[key="participants--dropdown"]');
                                                    const participantsIsCourseStaff = participantsDropdown.querySelector('[name="participants--dropdown--participants"][value="course-staff"]').checked;
                                                    for (const selectedParticipant of participantsDropdown.querySelectorAll('[key^="participants--dropdown--selected-participant--course-participant-reference--"]')) {
                                                      if (participantsIsCourseStaff && selectedParticipant.matches('[data-course-participant-course-role="course-staff"]'))
                                                        continue;
                                                      let selectedParticipantHidden = filterPhrases.length > 0;
                                                      for (const filterablePhrasesElement of selectedParticipant.querySelectorAll("[data-filterable-phrases]")) {
                                                        const filterablePhrases = JSON.parse(filterablePhrasesElement.getAttribute("data-filterable-phrases"));
                                                        const filterablePhrasesElementChildren = [];
                                                        for (const filterablePhrase of filterablePhrases) {
                                                          let filterablePhraseElement;
                                                          if (filterPhrases.some(filterPhrase => filterablePhrase.toLowerCase().startsWith(filterPhrase.toLowerCase()))) {
                                                            filterablePhraseElement = document.createElement("mark");
                                                            filterablePhraseElement.classList.add("mark");
                                                            selectedParticipantHidden = false;
                                                          } else
                                                            filterablePhraseElement = document.createElement("span");
                                                          filterablePhraseElement.textContent = filterablePhrase;
                                                          filterablePhrasesElementChildren.push(filterablePhraseElement);
                                                        }
                                                        filterablePhrasesElement.replaceChildren(...filterablePhrasesElementChildren);
                                                      }
                                                      selectedParticipant.hidden = selectedParticipantHidden;
                                                    }
                                                  };
                                                });

/********************************************************************************/

leafac.execute.functions.set("bdjlkikxwhsgig", function (event, $$0) {

                                                      this.isModified = false;

                                                      this.onchange = () => {
                                                        this.closest("form").querySelector($$0).checked = this.checked;
                                                      };
                                                    });

/********************************************************************************/

leafac.execute.functions.set("csfpsjmvtwltcf", function (event, $$0) {

                              leafac.setTippy({
                                event,
                                element: this,
                                tippyProps: {
                                  touch: false,
                                  content: "Remove Participant",
                                },
                              });

                              this.onclick = () => {
                                this.previousElementSibling.checked = false;

                                this.closest("form").querySelector('[key="participants"]').dropdown.props.content.querySelector($$0).checked = false;
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("dmmcpcdtxzvbte", function (event) {

                              leafac.setTippy({
                                event,
                                element: this,
                                tippyProps: {
                                  trigger: "click",
                                  content: "Course participants receive immediate email notifications for announcements.",
                                },
                              });
                            });

/********************************************************************************/

leafac.execute.functions.set("gdkhdguciyouns", function (event) {

                                this.onchange = () => {
                                  if (this.checked) this.closest("form").querySelector('[name="isPinned"]').checked = true;
                                };
                              });

/********************************************************************************/

leafac.execute.functions.set("dqphxvuvlbggdy", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Set as Announcement",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("bezwquphlfwvrv", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Set as Not an Announcement",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("bgdpnkafffobub", function (event) {

                              leafac.setTippy({
                                event,
                                element: this,
                                tippyProps: {
                                  trigger: "click",
                                  content: "Pinned conversations are listed first.",
                                },
                              });
                            });

/********************************************************************************/

leafac.execute.functions.set("bmlplysguoriou", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Pin",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("dyxtupdzjovczr", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Unpin",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("gkkkxmkzokbyjl", function (event, $$0) {

                                this.isModified = false;

                                this.onchange = async () => {
                                  await fetch($$0, {
                                    method: "PATCH",
                                    headers: { "CSRF-Protection": "true", },
                                    cache: "no-store",
                                    body: new URLSearchParams({ preferAnonymous: String(this.checked), }),
                                  });
                                };
                              });

/********************************************************************************/

leafac.execute.functions.set("mbgrdrjnvyhzc", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Set as Anonymous to Other Students",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("clkkjkhzvesskc", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Set as Signed by You",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("blrqalktahfbyd", function (event, $$0) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        touch: false,
                        content: $$0,
                      },
                    });

                    const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');

                    (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+enter", () => { this.click(); return false; });

                    this.onclick = () => {
                      delete this.closest("form").isValid;
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("fdcnrdpmnyodmz", function (event) {

                                this.hidden = leafac.isAppleDevice;
                              });

/********************************************************************************/

leafac.execute.functions.set("gculjmwuflqfas", function (event) {

                                this.hidden = !leafac.isAppleDevice;
                              });

/********************************************************************************/

leafac.execute.functions.set("bhrajnhdpqlhsu", function (event, $$0) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        touch: false,
                        content: $$0,  
                      },
                    });

                    const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');

                    // TODO: Conversation drafts
                    // (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+s", () => { this.click(); return false; });

                    this.onclick = () => {
                      this.closest("form").isValid = true;
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("gufidtfktralky", function (event) {

                            this.onclick = () => {
                              this.closest('[key="conversation--header"]').querySelector('[key="conversation--header--compact"]').hidden = true;
                              this.closest('[key="conversation--header"]').querySelector('[key="conversation--header--full"]').hidden = false;
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("eotmgskzswxwtw", function (event, $$0) {

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      tippyProps: {
                                        touch: false,
                                        content: "Update Conversation Type",
                                      },
                                    });

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      elementProperty: "dropdown",
                                      tippyProps: {
                                        trigger: "click",
                                        interactive: true,
                                        content: $$0,  
                                      },
                                    });
                                  });

/********************************************************************************/

leafac.execute.functions.set("fsueeflaesehm", function (event) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Set as Resolved",
                                                  },
                                                });
                                              });

/********************************************************************************/

leafac.execute.functions.set("dcyihoxarsrsxx", function (event) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Set as Unresolved",
                                                  },
                                                });
                                              });

/********************************************************************************/

leafac.execute.functions.set("elixpulsaiabsa", function (event) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Set as Announcement",
                                                  },
                                                });
                                              });

/********************************************************************************/

leafac.execute.functions.set("cctaacfkeemisn", function (event) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Set as Not an Announcement",
                                                  },
                                                });
                                              });

/********************************************************************************/

leafac.execute.functions.set("ohhhcicevxfkc", function (event) {

                                          leafac.setTippy({
                                            event,
                                            element: this,
                                            tippyProps: {
                                              touch: false,
                                              content: "Pin",
                                            },
                                          });
                                        });

/********************************************************************************/

leafac.execute.functions.set("crilkotalvybij", function (event) {

                                          leafac.setTippy({
                                            event,
                                            element: this,
                                            tippyProps: {
                                              touch: false,
                                              content: "Unpin",
                                            },
                                          });
                                        });

/********************************************************************************/

leafac.execute.functions.set("fjvumdjhidjvml", function (event, $$0) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Actions",
                              },
                            });

                            leafac.setTippy({
                              event,
                              element: this,
                              elementProperty: "dropdown",
                              tippyProps: {
                                trigger: "click",
                                interactive: true,
                                content: $$0,  
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("ehuowpbyxxtyaj", function (event, $$0) {

                                        leafac.setTippy({
                                          event,
                                          element: this,
                                          elementProperty: "copied",
                                          tippyProps: {
                                            theme: "green",
                                            trigger: "manual",
                                            content: "Copied",
                                          },
                                        });

                                        this.onclick = async () => {
                                          await navigator.clipboard.writeText($$0);
                                          this.copied.show();
                                          await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                                          this.copied.hide();
                                        };
                                      });

/********************************************************************************/

leafac.execute.functions.set("cdmvdwirjbopbg", function (event) {

                                              this.onclick = () => {
                                                this.closest('[key="conversation--header--full"]').querySelector('[key="title--show"]').hidden = true;
                                                this.closest('[key="conversation--header--full"]').querySelector('[key="title--edit"]').hidden = false;
                                                tippy.hideAll();
                                              };
                                            });

/********************************************************************************/

leafac.execute.functions.set("gdwouipkujztyn", function (event, $$0, $$1) {

                                              leafac.setTippy({
                                                event,
                                                element: this,
                                                tippyProps: {
                                                  trigger: "click",
                                                  interactive: true,
                                                  onHidden: () => { this.onmouseleave(); },
                                                  content: $$0,
                                                },
                                              });

                                              window.clearTimeout(this.tooltipContentTimeout);
                                              this.tooltipContentSkipLoading = false;
                                              
                                              this.onmouseenter = this.onfocus = async () => {
                                                window.clearTimeout(this.tooltipContentTimeout);
                                                if (this.tooltipContentSkipLoading) return;
                                                this.tooltipContentSkipLoading = true;
                                                leafac.loadPartial(this.tooltip.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                                                this.tooltip.props.content.querySelector('[key="loading"]').hidden = true;
                                                this.tooltip.props.content.querySelector('[key="content"]').hidden = false;
                                                this.tooltip.setProps({});
                                              };
                                              
                                              this.onmouseleave = this.onblur = () => {
                                                window.clearTimeout(this.tooltipContentTimeout);
                                                if (this.matches(":hover, :focus-within") || this.tooltip.state.isShown) return;
                                                this.tooltipContentTimeout = window.setTimeout(() => {
                                                  this.tooltip.props.content.querySelector('[key="loading"]').hidden = false;
                                                  this.tooltip.props.content.querySelector('[key="content"]').hidden = true;
                                                  this.tooltipContentSkipLoading = false;
                                                }, 60 * 1000);
                                              };
                                            });

/********************************************************************************/

leafac.execute.functions.set("tzxegqycqnvz", function (event, $$0) {

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  elementProperty: "dropdown",
                                                  tippyProps: {
                                                    theme: "rose",
                                                    trigger: "click",
                                                    interactive: true,
                                                    content: $$0,  
                                                  },
                                                });
                                              });

/********************************************************************************/

leafac.execute.functions.set("elcfkhevverjyl", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    theme: "green",
                                    touch: false,
                                    content: "Update Title",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("gqaayuhapxtgey", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    theme: "rose",
                                    touch: false,
                                    content: "Cancel",
                                  },
                                });

                                this.onclick = () => {
                                  this.closest('[key="conversation--header--full"]').querySelector('[key="title--show"]').hidden = false;
                                  this.closest('[key="conversation--header--full"]').querySelector('[key="title--edit"]').hidden = true;
                                };
                              });

/********************************************************************************/

leafac.execute.functions.set("hdkzcdmkfozgkd", function (event) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      theme: "rose",
                                      touch: false,
                                      content: "You may not remove this tag because a conversation must have at least one tag.",
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("exfyvjuvrzoex", function (event) {

                                        leafac.setTippy({
                                          event,
                                          element: this,
                                          tippyProps: {
                                            content: "This tag is visible by the course staff only.",
                                          },
                                        });
                                      });

/********************************************************************************/

leafac.execute.functions.set("bpuunaikgxofra", function (event) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      theme: "rose",
                                      touch: false,
                                      content: "Remove Tag",
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("dygpwbkrjzdogd", function (event, $$0) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Add Tag",
                                  },
                                });
                                
                                leafac.setTippy({
                                  event,
                                  element: this,
                                  elementProperty: "dropdown",
                                  tippyProps: {
                                    trigger: "click",
                                    interactive: true,
                                    content: $$0,  
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("dffcwuzmvlueoi", function (event) {

                                                                leafac.setTippy({
                                                                  event,
                                                                  element: this,
                                                                  tippyProps: {
                                                                    touch: false,
                                                                    content: "This tag is visible by the course staff only.",
                                                                  },
                                                                });
                                                              });

/********************************************************************************/

leafac.execute.functions.set("hbkzpzuvryurot", function (event) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          theme: "rose",
                                                          touch: false,
                                                          content: "You may not remove this tag because a conversation must have at least one tag.",
                                                        },
                                                      });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("fuctdlnvdrkchb", function (event) {

                                                              leafac.setTippy({
                                                                event,
                                                                element: this,
                                                                tippyProps: {
                                                                  touch: false,
                                                                  content: "This tag is visible by the course staff only.",
                                                                },
                                                              });
                                                            });

/********************************************************************************/

leafac.execute.functions.set("echtropfiiccjm", function (event) {

                                this.onbeforemorphattribute = (event, attribute) => ["hidden", "disabled", "checked"].includes(attribute);
                              });

/********************************************************************************/

leafac.execute.functions.set("hiylvwniommwqx", function (event, $$0) {

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      elementProperty: "dropdown",
                                      tippyProps: {
                                        trigger: "click",
                                        interactive: true,
                                        placement: "bottom",
                                        onHide: () => {
                                          const form = this.closest("form");
                                          if (leafac.isModified(form)) form.querySelector('[key="submit"] button').click();
                                        },
                                        content: $$0,  
                                      },
                                    });
                                  });

/********************************************************************************/

leafac.execute.functions.set("fjivxrpopnyvdw", function (event, $$0, $$1, $$2, $$3) {

                                                        this.isModified = false;

                                                        this.onchange = () => {
                                                          this.closest("form").querySelector($$0).checked = true;

                                                          const participantsDropdown = this.closest('[key="participants--dropdown"]');
                                                          const selectedParticipants = participantsDropdown.querySelector('[key="participants--dropdown--selected-participants"]');

                                                          if ($$1) {
                                                            selectedParticipants.hidden = true;

                                                            for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                              element.disabled = true;
                                                          } else if ($$2) {
                                                            selectedParticipants.hidden = false;
                                                            selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--course-staff"]').hidden = false;
                                                            selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--selected-participants"]').hidden = true;

                                                            for (const element of selectedParticipants.querySelectorAll('[data-course-participant-course-role="course-staff"]'))
                                                              element.hidden = true;
                                                            participantsDropdown.querySelector('[key="participants--dropdown--selected-participants--filter"]')?.oninput();

                                                            for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                              element.disabled = element.matches('[data-course-participant-course-role="course-staff"]');
                                                          } else if ($$3) {
                                                            selectedParticipants.hidden = false;
                                                            selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--course-staff"]').hidden = true;
                                                            selectedParticipants.querySelector('[key="participants--dropdown--selected-participants--separator--selected-participants"]').hidden = false;

                                                            for (const element of selectedParticipants.querySelectorAll('[data-course-participant-course-role="course-staff"]'))
                                                              element.hidden = false;
                                                            participantsDropdown.querySelector('[key="participants--dropdown--selected-participants--filter"]')?.oninput();

                                                            for (const element of this.closest("form").querySelectorAll('[name="selectedParticipantsReferences[]"]'))
                                                              element.disabled = false;
                                                          }
                                                        };
                                                      });

/********************************************************************************/

leafac.execute.functions.set("clrjdoxhcuifne", function (event) {

                                                            this.isModified = false;

                                                            this.oninput = () => {
                                                              const filterPhrases = this.value.split(/[^a-z0-9]+/i).filter((filterPhrase) => filterPhrase.trim() !== "");
                                                              const participantsDropdown = this.closest('[key="participants--dropdown"]');
                                                              const participantsIsCourseStaff = participantsDropdown.querySelector('[name="participants--dropdown--participants"][value="course-staff"]').checked;
                                                              for (const selectedParticipant of participantsDropdown.querySelectorAll('[key^="participants--dropdown--selected-participant--course-participant-reference--"]')) {
                                                                if (participantsIsCourseStaff && selectedParticipant.matches('[data-course-participant-course-role="course-staff"]'))
                                                                  continue;
                                                                let selectedParticipantHidden = filterPhrases.length > 0;
                                                                for (const filterablePhrasesElement of selectedParticipant.querySelectorAll("[data-filterable-phrases]")) {
                                                                  const filterablePhrases = JSON.parse(filterablePhrasesElement.getAttribute("data-filterable-phrases"));
                                                                  const filterablePhrasesElementChildren = [];
                                                                  for (const filterablePhrase of filterablePhrases) {
                                                                    let filterablePhraseElement;
                                                                    if (filterPhrases.some(filterPhrase => filterablePhrase.toLowerCase().startsWith(filterPhrase.toLowerCase()))) {
                                                                      filterablePhraseElement = document.createElement("mark");
                                                                      filterablePhraseElement.classList.add("mark");
                                                                      selectedParticipantHidden = false;
                                                                    } else
                                                                      filterablePhraseElement = document.createElement("span");
                                                                    filterablePhraseElement.textContent = filterablePhrase;
                                                                    filterablePhrasesElementChildren.push(filterablePhraseElement);
                                                                  }
                                                                  filterablePhrasesElement.replaceChildren(...filterablePhrasesElementChildren);
                                                                }
                                                                selectedParticipant.hidden = selectedParticipantHidden;
                                                              }
                                                            };
                                                          });

/********************************************************************************/

leafac.execute.functions.set("dkjzwzdslnohsu", function (event, $$0) {

                                                                this.isModified = false;

                                                                this.onchange = () => {
                                                                  this.closest("form").querySelector($$0).checked = this.checked;
                                                                };
                                                              });

/********************************************************************************/

leafac.execute.functions.set("darqtrhgrudpsz", function (event, $$0) {

                                        leafac.setTippy({
                                          event,
                                          element: this,
                                          tippyProps: {
                                            touch: false,
                                            content: "Remove Participant",
                                          },
                                        });

                                        this.onclick = () => {
                                          this.previousElementSibling.checked = false;

                                          this.closest("form").querySelector('[key="participants"]').dropdown.props.content.querySelector($$0).checked = false;

                                          this.closest("form").querySelector('[key="submit"]').hidden = !leafac.isModified(this.closest("form"));
                                        };
                                      });

/********************************************************************************/

leafac.execute.functions.set("gnbbkdjkudhywz", function (event) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Participants",
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("fsldwxklnudxap", function (event) {

                              this.onclick = () => {
                                this.closest('[key="conversation--header"]').querySelector('[key="conversation--header--full"]').hidden = true;
                                this.closest('[key="conversation--header"]').querySelector('[key="conversation--header--compact"]').hidden = false;
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("xskpniwpsnmkp", function (event, $$0, $$1, $$2, $$3, $$4, $$5, $$6) {

                      const scroll = () => {
                        if (
                          [undefined, "GET", "HEAD", "OPTIONS", "TRACE"].includes(event?.detail?.request?.method) &&
                          !event?.detail?.liveUpdate
                        ) {
                          if ($$0) {
                            const element = this.querySelector($$1);
                            if (element === null) return;
                            element.scrollIntoView();
                            const messageHighlight = element.querySelector('[key="message--highlight"]');
                            messageHighlight.style.animation = "message--highlight 2s var(--transition-timing-function--in-out)";
                            messageHighlight.onanimationend = () => {
                              messageHighlight.style.animation = "";
                            };
                          } else if ($$2) {
                            this.querySelector($$3)?.scrollIntoView();
                          } else if ($$4) {
                            this.scroll(0, this.scrollHeight);
                          }
                        }
                        else if ($$5 && this.shouldScrollConversationToBottom) {
                          this.scroll(0, this.scrollHeight);
                        }

                        if ($$6) {
                          this.onscroll = () => {
                            this.shouldScrollConversationToBottom = this.scrollTop === this.scrollHeight - this.offsetHeight;
                          };
                          this.onscroll();
                        }
                      };
                      window.addEventListener("livenavigateself", scroll);
                      window.addEventListener("livenavigate", () => {
                        window.removeEventListener("livenavigateself", scroll);
                      }, { once: true });
                      window.setTimeout(scroll);
                    });

/********************************************************************************/

leafac.execute.functions.set("czcbwistobdtos", function (event) {

                                                if (this !== document.querySelector('[key="message--new-separator"]')) {
                                                  this.remove();
                                                  return;
                                                }

                                                leafac.setTippy({
                                                  event,
                                                  element: this,
                                                  tippyProps: {
                                                    touch: false,
                                                    content: "Close",
                                                  },
                                                });
                                                          
                                                this.onclick = () => {
                                                  this.remove();
                                                };

                                                this.onbeforeremove = () => false;
                                              });

/********************************************************************************/

leafac.execute.functions.set("frmteacjzaqvni", function (event) {

                                                    const element = this;
                                                    leafac.relativizeDateElement(element);

                                                    window.clearTimeout(element.updateTimeout);
                                                    (function update() {
                                                      if (!leafac.isConnected(element)) return;
                                                      const dateSeparators = [...document.querySelectorAll('[key="message--date-separator"]')];
                                                      const thisDateSeparator = element.closest('[key="message--date-separator"]');
                                                      const thisDateSeparatorIndex = dateSeparators.indexOf(thisDateSeparator);
                                                      const previousDateSeparator = thisDateSeparatorIndex <= 0 ? undefined : dateSeparators[thisDateSeparatorIndex - 1];
                                                      thisDateSeparator.hidden = previousDateSeparator !== undefined && previousDateSeparator.textContent === thisDateSeparator.textContent;
                                                      element.updateTimeout = window.setTimeout(update, 60 * 1000 + Math.random() * 10 * 1000);
                                                    })();
                                                  });

/********************************************************************************/

leafac.execute.functions.set("eszvxpzwupllrg", function (event) {

                                                  leafac.setTippy({
                                                    event,
                                                    element: this,
                                                    tippyProps: {
                                                      touch: false,
                                                      content: "Course staff whispers are messages visible to the course staff only.",
                                                    },
                                                  });
                                                });

/********************************************************************************/

leafac.execute.functions.set("bzwgtvkaxztoma", function (event, $$0, $$1) {

                                                    leafac.setTippy({
                                                      event,
                                                      element: this,
                                                      tippyProps: {
                                                        touch: false,
                                                        content: "Actions",
                                                      },
                                                    });

                                                    leafac.setTippy({
                                                      event,
                                                      element: this,
                                                      elementProperty: "dropdown",
                                                      tippyProps: {
                                                        trigger: "click",
                                                        interactive: true,
                                                        onHidden: () => { this.onmouseleave(); },
                                                        content: $$0,
                                                      },
                                                    });

                                                    window.clearTimeout(this.dropdownContentTimeout);
                                                    this.dropdownContentSkipLoading = false;

                                                    this.onmouseenter = this.onfocus = async () => {
                                                      window.clearTimeout(this.dropdownContentTimeout);
                                                      if (this.dropdownContentSkipLoading) return;
                                                      this.dropdownContentSkipLoading = true;
                                                      leafac.loadPartial(this.dropdown.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                                                      this.dropdown.props.content.querySelector('[key="loading"]').hidden = true;
                                                      this.dropdown.props.content.querySelector('[key="content"]').hidden = false;
                                                      this.dropdown.setProps({});
                                                    };

                                                    this.onmouseleave = this.onblur = () => {
                                                      window.clearTimeout(this.dropdownContentTimeout);
                                                      if (this.matches(":hover, :focus-within") || this.dropdown.state.isShown) return;
                                                      this.dropdownContentTimeout = window.setTimeout(() => {
                                                        this.dropdown.props.content.querySelector('[key="loading"]').hidden = false;
                                                        this.dropdown.props.content.querySelector('[key="content"]').hidden = true;
                                                        this.dropdownContentSkipLoading = false;
                                                      }, 60 * 1000);
                                                    };
                                                  });

/********************************************************************************/

leafac.execute.functions.set("bkdbdazhicpcsb", function (event, $$0) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          touch: false,
                                                          content: "Update Message Type",
                                                        },
                                                      });
                  
                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        elementProperty: "dropdown",
                                                        tippyProps: {
                                                          trigger: "click",
                                                          interactive: true,
                                                          content: $$0,  
                                                        },
                                                      });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("ckpobpoksopivk", function (event, $$0) {

                                                            leafac.setTippy({
                                                              event,
                                                              element: this,
                                                              tippyProps: {
                                                                touch: false,
                                                                content: $$0,  
                                                              },
                                                            });
                                                          });

/********************************************************************************/

leafac.execute.functions.set("dezswwocyeklua", function (event, $$0) {

                                                                  leafac.setTippy({
                                                                    event,
                                                                    element: this,
                                                                    tippyProps: {
                                                                      touch: false,
                                                                      content: $$0,  
                                                                    },
                                                                  });
                                                                });

/********************************************************************************/

leafac.execute.functions.set("chmgfdlhraeqpm", function (event, $$0, $$1) {

                                                    if ($$0)
                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          content: $$1,  
                                                        },
                                                      });
                                                  });

/********************************************************************************/

leafac.execute.functions.set("xdhxtmugtkgre", function (event) {

                                                      leafac.relativizeDateTimeElement(this, { capitalize: true });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("byalurfuuolqnr", function (event) {

                                                              leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                                                            });

/********************************************************************************/

leafac.execute.functions.set("gytkemwcxjyjds", function (event, $$0) {

                                                  const dropdownMenuTarget = this.closest('[key="message--show--content-area"]').querySelector('[key="message--show--content-area--dropdown-menu-target"]');
                                                  leafac.setTippy({
                                                    event,
                                                    element: dropdownMenuTarget,
                                                    elementProperty: "dropdownMenu",
                                                    tippyProps: {
                                                      trigger: "manual",
                                                      interactive: true,
                                                      content: $$0,  
                                                    },
                                                  });
                                                  
                                                  this.onmouseup = (event) => {
                                                    window.setTimeout(() => {
                                                      const selection = window.getSelection();
                                                      const anchorElement = leafac.ancestors(selection.anchorNode).reverse().find(element => typeof element?.getAttribute?.("data-position") === "string");
                                                      const focusElement = leafac.ancestors(selection.focusNode).reverse().find(element => typeof element?.getAttribute?.("data-position") === "string");
                                                      if (
                                                        selection.isCollapsed ||
                                                        anchorElement === undefined ||
                                                        focusElement === undefined ||
                                                        !this.contains(anchorElement) ||
                                                        !this.contains(focusElement) ||
                                                        anchorElement.closest('[key^="poll/"]') !== null ||
                                                        focusElement.closest('[key^="poll/"]') !== null
                                                      ) return;
                                                      dropdownMenuTarget.style.top = String(event.layerY) + "px";
                                                      dropdownMenuTarget.style.left = String(event.layerX) + "px";
                                                      dropdownMenuTarget.dropdownMenu.show();
                                                    });
                                                  };
                                                });

/********************************************************************************/

leafac.execute.functions.set("blfzccfbnuobzo", function (event, $$0, $$1, $$2) {

                                                              this.onclick = () => {
                                                                tippy.hideAll();
                                                                const selection = window.getSelection();
                                                                const anchorElement = leafac.ancestors(selection.anchorNode).reverse().find(element => typeof element?.getAttribute?.("data-position") === "string");
                                                                const focusElement = leafac.ancestors(selection.focusNode).reverse().find(element => typeof element?.getAttribute?.("data-position") === "string");
                                                                const contentElement = this.closest('[key="message--show--content-area"]').querySelector('[key="message--show--content-area--content"]');
                                                                if (
                                                                  selection.isCollapsed ||
                                                                  anchorElement === undefined ||
                                                                  focusElement === undefined ||
                                                                  !contentElement.contains(anchorElement) ||
                                                                  !contentElement.contains(focusElement)
                                                                ) return;
                                                                const anchorPosition = JSON.parse(anchorElement.getAttribute("data-position"));
                                                                const focusPosition = JSON.parse(focusElement.getAttribute("data-position"));
                                                                const start = Math.min(anchorPosition.start.offset, focusPosition.start.offset);
                                                                const end = Math.max(anchorPosition.end.offset, focusPosition.end.offset);
                                                                const content = anchorElement.closest("[data-content-source]").getAttribute("data-content-source");
                                                                const newMessage = document.querySelector('[key="new-message"]');
                                                                newMessage.querySelector('[key="content-editor--button--write"]')?.click();
                                                                const element = newMessage.querySelector('[key="content-editor--write--textarea"]');
                                                                textFieldEdit.wrapSelection(
                                                                  element,
                                                                  ((element.selectionStart > 0) ? "\n\n" : "") + "> " + $$0 + "#" + $$1 + "/" + $$2 + "\n>\n> " + content.slice(start, end).replaceAll("\n", "\n> ") + "\n\n",
                                                                  ""
                                                                );
                                                                element.focus();
                                                              };
                                                            });

/********************************************************************************/

leafac.execute.functions.set("dkymqafsognewl", function (event, $$0) {

                                                                leafac.setTippy({
                                                                  event,
                                                                  element: this,
                                                                  tippyProps: {
                                                                    touch: false,
                                                                    content: $$0,
                                                                  },
                                                                });
                                                              });

/********************************************************************************/

leafac.execute.functions.set("daopmduulsdciv", function (event, $$0, $$1) {

                                                              leafac.setTippy({
                                                                event,
                                                                element: this,
                                                                tippyProps: {
                                                                  touch: false,
                                                                  content: "See course participants who liked",
                                                                },
                                                              });
                                                              
                                                              leafac.setTippy({
                                                                event,
                                                                element: this,
                                                                elementProperty: "dropdown",
                                                                tippyProps: {
                                                                  trigger: "click",
                                                                  interactive: true,
                                                                  onHidden: () => { this.onmouseleave(); },
                                                                  content: $$0,
                                                                },
                                                              });

                                                              window.clearTimeout(this.dropdownContentTimeout);
                                                              this.dropdownContentSkipLoading = false;
                                                              
                                                              this.onmouseenter = this.onfocus = async () => {
                                                                window.clearTimeout(this.dropdownContentTimeout);
                                                                if (this.dropdownContentSkipLoading) return;
                                                                this.dropdownContentSkipLoading = true;
                                                                leafac.loadPartial(this.dropdown.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                                                                this.dropdown.props.content.querySelector('[key="loading"]').hidden = true;
                                                                this.dropdown.props.content.querySelector('[key="content"]').hidden = false;
                                                                this.dropdown.setProps({});
                                                              };
                                                              
                                                              this.onmouseleave = this.onblur = () => {
                                                                window.clearTimeout(this.dropdownContentTimeout);
                                                                if (this.matches(":hover, :focus-within") || this.dropdown.state.isShown) return;
                                                                this.dropdownContentTimeout = window.setTimeout(() => {
                                                                  this.dropdown.props.content.querySelector('[key="loading"]').hidden = false;
                                                                  this.dropdown.props.content.querySelector('[key="content"]').hidden = true;
                                                                  this.dropdownContentSkipLoading = false;
                                                                }, 60 * 1000);
                                                              };
                                                            });

/********************************************************************************/

leafac.execute.functions.set("hbccbravsahvos", function (event, $$0, $$1) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          trigger: "click",
                                                          interactive: true,
                                                          onHidden: () => { this.onmouseleave(); },
                                                          content: $$0,
                                                        },
                                                      });

                                                      window.clearTimeout(this.tooltipContentTimeout);
                                                      this.tooltipContentSkipLoading = false;
                                                      
                                                      this.onmouseenter = this.onfocus = async () => {
                                                        window.clearTimeout(this.tooltipContentTimeout);
                                                        if (this.tooltipContentSkipLoading) return;
                                                        this.tooltipContentSkipLoading = true;
                                                        leafac.loadPartial(this.tooltip.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                                                        this.tooltip.props.content.querySelector('[key="loading"]').hidden = true;
                                                        this.tooltip.props.content.querySelector('[key="content"]').hidden = false;
                                                        this.tooltip.setProps({});
                                                      };
                                                      
                                                      this.onmouseleave = this.onblur = () => {
                                                        window.clearTimeout(this.tooltipContentTimeout);
                                                        if (this.matches(":hover, :focus-within") || this.tooltip.state.isShown) return;
                                                        this.tooltipContentTimeout = window.setTimeout(() => {
                                                          this.tooltip.props.content.querySelector('[key="loading"]').hidden = false;
                                                          this.tooltip.props.content.querySelector('[key="content"]').hidden = true;
                                                          this.tooltipContentSkipLoading = false;
                                                        }, 60 * 1000);
                                                      };
                                                    });

/********************************************************************************/

leafac.execute.functions.set("bmiajbegnaaquq", function (event) {

                                                if (event?.detail?.liveUpdate && !this.closest('[key="message--edit"]').hidden) return;
                                                this.partialParentElement = false;
                                                this.skipLoading = false;
                                              });

/********************************************************************************/

leafac.execute.functions.set("blczqoohbxwpxi", function (event, $$0, $$1) {

                  this.isModified = false;

                  this.isUpdating ??= false;
                  this.shouldUpdateAgain ??= false;
                  this.oninput = async () => {
                    if (this.isUpdating) {
                      this.shouldUpdateAgain = true;
                      return;
                    }
                    this.isUpdating = true;
                    this.shouldUpdateAgain = false;
                    await fetch($$0, {
                      method: "POST",
                      headers: { "CSRF-Protection": "true", },
                      cache: "no-store",
                      body: new URLSearchParams(new FormData(this)),
                    });
                    this.isUpdating = false;
                    if (this.shouldUpdateAgain) this.oninput();
                  };

                  this.onsubmit = () => {
                    window.setTimeout(() => {
                      const placeholder = document.querySelector('[key="message--new-message--placeholder"]');
                      const content = this.querySelector('[name="content"]');
                      if ($$1)
                        placeholder.querySelector('[key="message--new-message--placeholder--anonymous--' + (!this.querySelector('[name="isAnonymous"]').checked).toString() + '"]').hidden = true;
                      placeholder.querySelector('[key="message--new-message--placeholder--content"]').textContent = content.value;
                      placeholder.hidden = false;
                      for (const element of leafac.ancestors(placeholder))
                        element.scroll(0, element.scrollHeight);
                      textFieldEdit.set(content, "");
                    });
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("dodkxkdaoscsqa", function (event, $$0) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: $$0,
                                  },
                                });

                                const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');

                                (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+enter", () => { this.click(); return false; });
                              });

/********************************************************************************/

leafac.execute.functions.set("eiaxptffaqjnfc", function (event) {

                                            this.hidden = leafac.isAppleDevice;
                                          });

/********************************************************************************/

leafac.execute.functions.set("ereyqdqjdlvllx", function (event) {

                                            this.hidden = !leafac.isAppleDevice;
                                          });

/********************************************************************************/

leafac.execute.functions.set("hbqopjbpotoxox", function (event, $$0) {

                                  this.isModified = false;

                                  this.onchange = async () => {
                                    await fetch($$0, {
                                      method: "PATCH",
                                      headers: { "CSRF-Protection": "true", },
                                      cache: "no-store",
                                      body: new URLSearchParams({ preferAnonymous: String(this.checked), }),
                                    });
                                  };
                                });

/********************************************************************************/

leafac.execute.functions.set("erlwokxgtxgqnf", function (event) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      touch: false,
                                      content: "Set as Anonymous to Other Students",
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("ktlweivjachnr", function (event) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      touch: false,
                                      content: "Set as Signed by You",
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("dgioikqsvbszix", function (event, $$0, $$1) {

                                if ($$0) return;

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: $$1,
                                  },
                                });
      
                                const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');
      
                                (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+enter", () => { this.click(); return false; });
                              });

/********************************************************************************/

leafac.execute.functions.set("kbbglpbyfypll", function (event, $$0, $$1) {

                                      if ($$0) return;

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        tippyProps: {
                                          touch: false,
                                          content: $$1,
                                        },
                                      });
            
                                      const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');
            
                                      (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+enter", () => { this.click(); return false; });
                                    });

/********************************************************************************/

leafac.execute.functions.set("ftgsdbnplrhmda", function (event) {

                                                  this.hidden = leafac.isAppleDevice;
                                                });

/********************************************************************************/

leafac.execute.functions.set("ezswsgcyeefeyw", function (event) {

                                                  this.hidden = !leafac.isAppleDevice;
                                                });

/********************************************************************************/

leafac.execute.functions.set("dvpsjpvlesrzqn", function (event) {

                                        leafac.setTippy({
                                          event,
                                          element: this,
                                          tippyProps: {
                                            trigger: "click",
                                            content: "Course staff whispers are messages visible to the course staff only.",
                                          },
                                        });
                                      });

/********************************************************************************/

leafac.execute.functions.set("dkvovaewomhktd", function (event) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        trigger: "click",
                        content: "To join an existing course you either have to follow an invitation link or be invited via email. Contact your course staff for more information.",
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("gbcbzvxsmhxrjg", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              trigger: "click",
                              interactive: true,
                              content: $$0,
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("dusbygeprytfrw", function (event) {

                    this.defaultValue = new Date().getFullYear().toString();
                  });

/********************************************************************************/

leafac.execute.functions.set("dqiswarsgtzkqa", function (event) {

                    const month = new Date().getMonth() + 1;
                    this.defaultValue = month < 4 || month > 9 ? "Spring" : "Fall";
                  });

/********************************************************************************/

leafac.execute.functions.set("bkllcseeaixirf", function (event, $$0, $$1, $$2, $$3) {

            this.style.setProperty("--color--accent-color--100", $$0);
            this.style.setProperty("--color--accent-color--200", $$1);
            this.style.setProperty("--color--accent-color--700", $$2);
            this.style.setProperty("--color--accent-color--800", $$3);
          });

/********************************************************************************/

leafac.execute.functions.set("ckvwmowchsgjdq", function (event) {

            this.onclick = () => {
              for (const element of leafac.nextSiblings(this).slice(1))
                element.hidden = !element.hidden;
            };
          });

/********************************************************************************/

leafac.execute.functions.set("fjdhjqofaiiprv", function (event) {

                      this.isModified = true;
                    });

/********************************************************************************/

leafac.execute.functions.set("dyzsbfyygmptao", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Set as Visible by Course Staff Only",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("dnpbgmnhwmctga", function (event) {

                leafac.setTippy({
                  event,
                  element: this,
                  tippyProps: {
                    touch: false,
                    content: "Set as Visible by Everyone",
                  },
                });
              });

/********************************************************************************/

leafac.execute.functions.set("pqgiejeqtqoyp", function (event, $$0, $$1) {

              leafac.setTippy({
                event,
                element: this,
                tippyProps: {
                  theme: "rose",
                  touch: false,
                  content: "Remove Tag",
                },
              });

              if ($$0)
                leafac.setTippy({
                  event,
                  element: this,
                  elementProperty: "dropdown",
                  tippyProps: {
                    theme: "rose",
                    trigger: "click",
                    interactive: true,
                    content: $$1,  
                  },
                });
              else
                this.onclick = () => {
                  const tags = this.closest('[key="tags"]');
                  this.closest('[key^="tag/"]').remove();
                  tags.reorder();
                };
            });

/********************************************************************************/

leafac.execute.functions.set("tohikuxnwzxkt", function (event) {

                            this.onclick = () => {
                              const tag = this.closest('[key^="tag/"]');
                              tag.classList.add("removed");
                              for (const element of leafac.descendants(tag)) {
                                if (element.skipDisable === true) continue;
                                if (typeof element.disabled === "boolean") element.disabled = true;
                                if (element.matches(".button")) element.classList.add("disabled");
                                if (element.tooltip !== undefined) element.tooltip.disable();
                              }
                              this.closest('[key="tags"]').reorder();
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("dqgwqbchycszkn", function (event) {

              this.skipDisable = true;

              leafac.setTippy({
                event,
                element: this,
                tippyProps: {
                  touch: false,
                  content: "Don’t Remove Tag",
                },
              });

              this.onclick = () => {
                const tag = this.closest('[key^="tag/"]');
                tag.classList.remove("removed");
                for (const element of leafac.descendants(tag)) {
                  if (typeof element.disabled === "boolean") element.disabled = false;
                  if (element.matches(".button")) element.classList.remove("disabled");
                  if (element.tooltip !== undefined) element.tooltip.enable();
                }
                tag.closest('[key="tags"]').reorder();
              };
            });

/********************************************************************************/

leafac.execute.functions.set("zosdkinxejuor", function (event) {

                  leafac.setTippy({
                    event,
                    element: this,
                    tippyProps: {
                      touch: false,
                      content: "See Conversations with This Tag",
                    },
                  });
                });

/********************************************************************************/

leafac.execute.functions.set("ddqllapnltbski", function (event) {

                  this.onbeforemorph = (event) => {
                    const liveUpdate = event?.detail?.liveUpdate;
                    if (!liveUpdate) this.isModified = false;
                    return !liveUpdate;
                  };

                  this.onpointerdown = (event) => {
                    if (event.target.closest('[key="tag--grab--handle"]') === null) return;

                    const body = document.querySelector("body");
                    const tag = event.target.closest('[key^="tag/"]');

                    this.grabbed = tag;
                    body.classList.add("grabbing");
                    tag.classList.add("grabbed");

                    body.addEventListener("pointerup", () => {
                      delete this.grabbed;
                      body.classList.remove("grabbing");
                      tag.classList.remove("grabbed");
                    }, { once: true });
                  };

                  this.onpointermove = (event) => {
                    const tag = (
                      event.pointerType === "touch" ? document.elementFromPoint(event.clientX, event.clientY) : event.target
                    ).closest('[key^="tag/"]');
                    if (tag === null || [undefined, tag].includes(this.grabbed)) return;

                    const boundingClientRect = tag.getBoundingClientRect();
                    tag[
                      (event.clientY - boundingClientRect.top) / (boundingClientRect.bottom - boundingClientRect.top) < 0.5 ?
                      "after" : "before"
                    ](this.grabbed);
                    this.reorder();
                  };

                  this.onkeydown = (event) => {
                    if (event.target.closest('[key="tag--grab--handle"]') === null) return;

                    const tag = event.target.closest('[key^="tag/"]');
                    switch (event.code) {
                      case "ArrowUp":
                        event.preventDefault();
                        tag.previousElementSibling?.before?.(tag);
                        break;
                      case "ArrowDown":
                        event.preventDefault();
                        tag.nextElementSibling?.after?.(tag);
                        break;
                    }
                    tag.querySelector('[key="tag--grab--handle"]').focus();
                    this.reorder();
                  };

                  this.reorder = () => {
                    this.isModified = true;

                    for (const [order, tag] of this.querySelectorAll('[key^="tag/"]:not(.removed)').entries())
                      for (const element of tag.querySelectorAll('[name^="tags["]'))
                        element.setAttribute("name", element.getAttribute("name").replace(/\d+/, String(order)));
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("flbxprgiqrtald", function (event, $$0) {

                    this.onclick = () => {
                      const newTag = leafac.stringToElement($$0).querySelector('[key="tag/new"]');

                      const tags = this.closest("form").querySelector('[key="tags"]');
                      tags.insertAdjacentElement("beforeend", newTag);
                      leafac.execute({ element: newTag });
                      tags.reorder();
                    };

                    this.onvalidate = () => {
                      if (this.closest("form").querySelector('[key="tags"]').children.length === 0)
                        return "Please add at least one tag.";
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("ecgjtrjiduumye", function (event) {

                        this.onchange = () => {
                          const form = this.closest("form");
                          const emails = form.querySelector('[key="emails"]');
                          emails.hidden = true;
                          for (const element of leafac.descendants(emails))
                            if (element.disabled !== undefined) element.disabled = true;
                          form.querySelector('[key="button--create-invitation"]').hidden = false;
                          form.querySelector('[key="button--send-invitation-emails"]').hidden = true;
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("ewmzyemenadwhr", function (event) {

                        this.onchange = () => {
                          const form = this.closest("form");
                          const emails = form.querySelector('[key="emails"]');
                          emails.hidden = false;
                          for (const element of leafac.descendants(emails))
                            if (element.disabled !== undefined) element.disabled = false;
                          form.querySelector('[key="button--create-invitation"]').hidden = true;
                          form.querySelector('[key="button--send-invitation-emails"]').hidden = false;
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("hhwzsaolgesynb", function (event, $$0) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          trigger: "click",
                          content: $$0,  
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("ezwaxjjnwfyfii", function (event) {

                    this.onvalidate = () => {
                      const emails = [];
                      for (let email of this.value.split(/[,\n]/)) {
                        email = email.trim();
                        let name = null;
                        const match = email.match(/^(?<name>.*)<(?<email>.*)>$/);
                        if (match !== null) {
                          email = match.groups.email.trim();
                          name = match.groups.name.trim();
                          if (name.startsWith('"') && name.endsWith('"'))
                            name = name.slice(1, -1);
                          if (name === "") name = null;
                        }
                        if (email === "") continue;
                        emails.push({ email, name });
                      }
                      if (
                        emails.length === 0 ||
                        emails.some(
                          ({ email }) => email.match(leafac.regExps.email) === null
                        )
                      )
                        return "Match the requested format.";
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("bmjfumglnvstqt", function (event) {

                        this.onchange = () => {
                          const expiresAt = this.closest("form").querySelector('[key="expires-at"]');
                          expiresAt.hidden = !this.checked;
                          for (const element of leafac.descendants(expiresAt))
                            if (element.disabled !== undefined) element.disabled = !this.checked;
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("gbiyabexqzmdfn", function (event) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: "Set as Expiring",
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("etfxqrnrzaqxxa", function (event) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: "Set as Not Expiring",
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("dzhwmvwptlvtub", function (event) {

                        this.value = this.defaultValue = leafac.localizeDateTime(this.defaultValue);

                        this.onvalidate = () => {
                          const error = leafac.validateLocalizedDateTime(this);
                          if (typeof error === "string") return error;
                          if (new Date(this.value).getTime() <= Date.now()) return "Must be in the future.";
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("edwdoptjvtnfdt", function (event) {

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      tippyProps: {
                                        touch: false,
                                        content: "Invitation Link",
                                      },
                                    });
                                  });

/********************************************************************************/

leafac.execute.functions.set("dkpkclitnwcpqw", function (event) {

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      tippyProps: {
                                        touch: false,
                                        content: "Invitation Email",
                                      },
                                    });
                                  });

/********************************************************************************/

leafac.execute.functions.set("fchrefbxqxsreu", function (event, $$0) {

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        tippyProps: {
                                          touch: false,
                                          content: "See Invitation Link",
                                        },
                                      });

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        elementProperty: "dropdown",
                                        tippyProps: {
                                          trigger: "click",
                                          interactive: true,
                                          maxWidth: "none",
                                          content: $$0,
                                        },
                                      });
                                    });

/********************************************************************************/

leafac.execute.functions.set("snsaejadcmuqh", function (event) {

                                                      this.onfocus = () => {
                                                        this.select();
                                                      };
                                                    });

/********************************************************************************/

leafac.execute.functions.set("cghbtykslaigkh", function (event, $$0) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          touch: false,
                                                          content: "Copy Link",
                                                        },
                                                      });

                                                      this.onclick = async () => {
                                                        await navigator.clipboard.writeText($$0);
                                                        const stickies = this.querySelector('[key="stickies"]');
                                                        const check = this.querySelector('[key="check"]');
                                                        stickies.hidden = true;
                                                        check.hidden = false;
                                                        await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                                                        stickies.hidden = false;
                                                        check.hidden = true;
                                                      };
                                                    });

/********************************************************************************/

leafac.execute.functions.set("bfjetetfptmzsl", function (event) {

                                                      leafac.setTippy({
                                                        event,
                                                        element: this,
                                                        tippyProps: {
                                                          touch: false,
                                                          content: "See QR Code for Link",
                                                        },
                                                      });
                                                    });

/********************************************************************************/

leafac.execute.functions.set("cieopjferotuxs", function (event, $$0) {

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        elementProperty: "dropdown",
                                        tippyProps: {
                                          trigger: "click",
                                          interactive: true,
                                          content: $$0,  
                                        },
                                      });
                                    });

/********************************************************************************/

leafac.execute.functions.set("eisoalousyfmiu", function (event) {

                                                          leafac.setTippy({
                                                            event,
                                                            element: this,
                                                            tippyProps: {
                                                              theme: "rose",
                                                              trigger: "click",
                                                              content: "You may not resend this invitation because it’s used.",
                                                            },
                                                          });
                                                        });

/********************************************************************************/

leafac.execute.functions.set("deiqiqsduqtmus", function (event) {

                                                          leafac.setTippy({
                                                            event,
                                                            element: this,
                                                            tippyProps: {
                                                              theme: "rose",
                                                              trigger: "click",
                                                              content: "You may not resend this invitation because it’s expired.",
                                                            },
                                                          });
                                                        });

/********************************************************************************/

leafac.execute.functions.set("cinauyfystpoed", function (event, $$0) {

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    tippyProps: {
                                      touch: false,
                                      content: "Update Course Role",
                                    },
                                  });

                                  leafac.setTippy({
                                    event,
                                    element: this,
                                    elementProperty: "dropdown",
                                    tippyProps: {
                                      trigger: "click",
                                      interactive: true,
                                      content: $$0,  
                                    },
                                  });
                                });

/********************************************************************************/

leafac.execute.functions.set("fzvduqpicemexn", function (event) {

                                                            leafac.setTippy({
                                                              event,
                                                              element: this,
                                                              tippyProps: {
                                                                theme: "rose",
                                                                trigger: "click",
                                                                content: "You may not update the course role of this invitation because it’s used.",
                                                              },
                                                            });
                                                          });

/********************************************************************************/

leafac.execute.functions.set("gkcjvjklbemwnm", function (event) {

                                                            leafac.setTippy({
                                                              event,
                                                              element: this,
                                                              tippyProps: {
                                                                theme: "rose",
                                                                trigger: "click",
                                                                content: "You may not update the course role of this invitation because it’s expired.",
                                                              },
                                                            });
                                                          });

/********************************************************************************/

leafac.execute.functions.set("goczgnhhilvexe", function (event) {

                                          this.value = this.defaultValue = leafac.localizeDateTime(this.defaultValue);

                                          this.onvalidate = () => {
                                            const error = leafac.validateLocalizedDateTime(this);
                                            if (typeof error === "string") return error;
                                            if (new Date(this.value).getTime() <= Date.now()) return "Must be in the future.";
                                          };
                                        });

/********************************************************************************/

leafac.execute.functions.set("ouubxppovpcyv", function (event, $$0) {

                                            leafac.setTippy({
                                              event,
                                              element: this,
                                              tippyProps: {
                                                interactive: true,
                                                content: $$0,
                                              },
                                            });
                                          });

/********************************************************************************/

leafac.execute.functions.set("ggfjeqisfnvgea", function (event) {

                                                        leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                                                      });

/********************************************************************************/

leafac.execute.functions.set("bifghjgomepmvb", function (event, $$0) {

                                            leafac.setTippy({
                                              event,
                                              element: this,
                                              tippyProps: {
                                                touch: false,
                                                content: "Update Expiration",
                                              },
                                            });

                                            leafac.setTippy({
                                              event,
                                              element: this,
                                              elementProperty: "dropdown",
                                              tippyProps: {
                                                trigger: "click",
                                                interactive: true,
                                                content: $$0,  
                                              },
                                            });
                                          });

/********************************************************************************/

leafac.execute.functions.set("cxxyhlxqiytzgm", function (event) {

                                                            leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                                                          });

/********************************************************************************/

leafac.execute.functions.set("farskcelhbmnqd", function (event, $$0) {

                  this.onclick = () => {
                    tippy.hideAll();
                    const button = document.querySelector($$0);
                    button.click();
                    button.tooltip.hide();
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("qqsyneauakcug", function (event) {

                              this.onfocus = () => {
                                this.select();
                              };
                            });

/********************************************************************************/

leafac.execute.functions.set("bgcmogqgpuxyue", function (event, $$0) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  tippyProps: {
                                    touch: false,
                                    content: "Copy Link",
                                  },
                                });

                                this.onclick = async () => {
                                  await navigator.clipboard.writeText($$0);
                                  const stickies = this.querySelector('[key="stickies"]');
                                  const check = this.querySelector('[key="check"]');
                                  stickies.hidden = true;
                                  check.hidden = false;
                                  await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                                  stickies.hidden = false;
                                  check.hidden = true;
                                };
                              });

/********************************************************************************/

leafac.execute.functions.set("cmmfvnfbuznftm", function (event) {

                  this.isModified = false;

                  this.oninput = () => {
                    const filterPhrases = this.value.split(/[^a-z0-9]+/i).filter((filterPhrase) => filterPhrase.trim() !== "");
                    for (const courseParticipant of document.querySelectorAll('[key^="course-participant/"]')) {
                      let courseParticipantHidden = filterPhrases.length > 0;
                      for (const filterablePhrasesElement of courseParticipant.querySelectorAll("[data-filterable-phrases]")) {
                        const filterablePhrases = JSON.parse(filterablePhrasesElement.getAttribute("data-filterable-phrases"));
                        const filterablePhrasesElementChildren = [];
                        for (const filterablePhrase of filterablePhrases) {
                          let filterablePhraseElement;
                          if (filterPhrases.some(filterPhrase => filterablePhrase.toLowerCase().startsWith(filterPhrase.toLowerCase()))) {
                            filterablePhraseElement = document.createElement("mark");
                            filterablePhraseElement.classList.add("mark");
                            courseParticipantHidden = false;
                          } else
                            filterablePhraseElement = document.createElement("span");
                          filterablePhraseElement.textContent = filterablePhrase;
                          filterablePhrasesElementChildren.push(filterablePhraseElement);
                        }
                        filterablePhrasesElement.replaceChildren(...filterablePhrasesElementChildren);
                      }
                      courseParticipant.hidden = courseParticipantHidden;
                    }
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("geqwjlvubitcxk", function (event) {

                    this.onbeforemorph = (event) => !event?.detail?.liveUpdate;
                  });

/********************************************************************************/

leafac.execute.functions.set("gmzcmjfdaeqpbs", function (event, $$0) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Copy Email",
                              },
                            });


                            leafac.setTippy({
                              event,
                              element: this,
                              elementProperty: "copied",
                              tippyProps: {
                                theme: "green",
                                trigger: "manual",
                                content: "Copied",
                              },
                            });

                            this.onclick = async () => {
                              await navigator.clipboard.writeText($$0);
                              this.copied.show();
                              await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                              this.copied.hide();
                            };
                          });

/********************************************************************************/

leafac.execute.functions.set("dzwmfrppzjzrmp", function (event, $$0) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                touch: false,
                                content: "Update Course Role",
                              },
                            });

                            leafac.setTippy({
                              event,
                              element: this,
                              elementProperty: "dropdown",
                              tippyProps: {
                                trigger: "click",
                                interactive: true,
                                content: $$0,  
                              },
                            });
                          });

/********************************************************************************/

leafac.execute.functions.set("bqdaojwrtoefts", function (event) {

                                                        leafac.setTippy({
                                                          event,
                                                          element: this,
                                                          tippyProps: {
                                                            theme: "rose",
                                                            trigger: "click",
                                                            content: "You may not update your own course role because you’re the only course staff member.",
                                                          },
                                                        });
                                                      });

/********************************************************************************/

leafac.execute.functions.set("cvfnoskjymurkv", function (event, $$0) {

                                                        leafac.setTippy({
                                                          event,
                                                          element: this,
                                                          elementProperty: "dropdown",
                                                          tippyProps: {
                                                            theme: "rose",
                                                            trigger: "click",
                                                            interactive: true,
                                                            appendTo: document.querySelector("body"),
                                                            content: $$0,  
                                                          },
                                                        });
                                                      });

/********************************************************************************/

leafac.execute.functions.set("cernxzzmsvoqpn", function (event, $$0, $$1) {

                            leafac.setTippy({
                              event,
                              element: this,
                              tippyProps: {
                                theme: "rose",
                                touch: false,
                                content: "Remove from the Course",
                              },
                            });

                            if ($$0)
                              leafac.setTippy({
                                event,
                                element: this,
                                elementProperty: "dropdown",
                                tippyProps: {
                                  theme: "rose",
                                  trigger: "click",
                                  content: "You may not remove yourself from the course because you’re the only course staff member.",
                                },
                              });
                            else
                              leafac.setTippy({
                                event,
                                element: this,
                                elementProperty: "dropdown",
                                tippyProps: {
                                  theme: "rose",
                                  trigger: "click",
                                  interactive: true,
                                  content: $$1,  
                                },
                              });
                          });

/********************************************************************************/

leafac.execute.functions.set("cslspnctqfqyqg", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          trigger: "click",
                          content: "A bar with the accent color appears at the top of pages related to this course to help you differentiate between courses.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("erlhqbrimvomxx", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          trigger: "click",
                          content: "You must confirm the course name to avoid removing yourself from the wrong course by accident.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("bhjppcvhtjgtnm", function (event, $$0) {

                    this.onvalidate = () => {
                      if (this.value !== $$0)
                        return "Please confirm the course name.";
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("cqcnmtmvdhpwcq", function (event, $$0) {

                    leafac.setTippy({
                      event,
                      element: this,
                      elementProperty: "dropdown",
                      tippyProps: {
                        theme: "rose",
                        trigger: "click",
                        interactive: true,
                        onShow: () => leafac.validate(this.closest("form")),
                        content: $$0,  
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("ffecemskjvupzw", function (event) {

                  this.onclick = () => {
                    document.querySelector('[key="update-email"]').hidden = false;
                  };
              });

/********************************************************************************/

leafac.execute.functions.set("etttlyxlvljulz", function (event) {

                    this.onvalidate = () => {
                      if (!leafac.isModified(this))
                        return "Please provide the email address to which you’d like to update.";
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("dxlhguzbjtvzpm", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          trigger: "click",
                          content: "You must confirm your password because this is an important operation that affects your account.",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("beqrxmxfwbrlpo", function (event, $$0, $$1, $$2, $$3, $$4, $$5, $$6, $$7) {

            if ($$0) {
              leafac.setTippy({
                event,
                element: this,
                elementProperty: "flash",
                tippyProps: {
                  appendTo: this,
                  trigger: "manual",
                  hideOnClick: false,
                  theme: $$1,
                  arrow: false,
                  interactive: true,
                  content: $$2,  
                },
              });
              this.flash.show();
            }

            document.querySelector('[key="theme-color--light"]').setAttribute("content", getComputedStyle(document.documentElement).getPropertyValue($$3));
            document.querySelector('[key="theme-color--dark"]').setAttribute("content", getComputedStyle(document.documentElement).getPropertyValue($$4));

            if ($$5 && event?.detail?.liveUpdate !== true)
              leafac.liveConnection({
                nonce: $$6,
                newServerVersionMessage: "Courselore has been updated. Please reload the page.",
                offlineMessage: "Failed to connect to Courselore. Please check your internet connection and try reloading the page.",
                environment: $$7,
              });
          });

/********************************************************************************/

leafac.execute.functions.set("dywqanxkmdldcf", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              theme: $$0,
                              touch: false,
                              content: "Close",
                            },
                          });

                          this.onclick = () => {
                            this.closest("[data-tippy-root]")._tippy.hide();
                          };
                        });

/********************************************************************************/

leafac.execute.functions.set("ejhssnuiwtweya", function (event) {

            this.onscroll = () => {
              this.scroll(0, 0);
            };
          });

/********************************************************************************/

leafac.execute.functions.set("ezbjiixfhsnljj", function (event, $$0, $$1, $$2) {

                      this.style.setProperty("--color--accent-color--500", $$0);
                      this.style.setProperty("--color--accent-color--600", $$1);

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "What’s This?",
                        },
                      });

                      leafac.setTippy({
                        event,
                        element: this,
                        elementProperty: "dropdown",
                        tippyProps: {
                          trigger: "click",
                          interactive: true,
                          content: $$2,  
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("hacllyowdvxyhg", function (event, $$0) {

                      leafac.setTippy({
                        event,
                        element: this,
                        elementProperty: "dropdown",
                        tippyProps: {
                          trigger: "click",
                          interactive: true,
                          content: $$0,  
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("eqvvpizcazzbgg", function (event, $$0) {

                                    this.onclick = async () => {
                                      this.classList.add("button--amber");
                                      await new Promise((resolve) => { window.setTimeout(resolve, 3 * 1000); });
                                      await fetch($$0, { cache: "no-store" });
                                      this.classList.remove("button--amber");
                                      this.classList.add("button--green");
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("gnnqxvgecfrwjf", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Courselore",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("eweaghxozpbicr", function (event, $$0) {

                                leafac.setTippy({
                                  event,
                                  element: this,
                                  elementProperty: "dropdown",
                                  tippyProps: {
                                    trigger: "click",
                                    interactive: true,
                                    content: $$0,  
                                  },
                                });
                              });

/********************************************************************************/

leafac.execute.functions.set("fppczrxbcrtwpg", function (event, $$0, $$1) {

                              leafac.setTippy({
                                event,
                                element: this,
                                tippyProps: {
                                  touch: false,
                                  content: $$0,  
                                },
                              });

                              leafac.setTippy({
                                event,
                                element: this,
                                elementProperty: "dropdown",
                                tippyProps: {
                                  trigger: "click",
                                  interactive: true,
                                  content: $$1,  
                                },
                              });
                            });

/********************************************************************************/

leafac.execute.functions.set("fcqvseayqawrdx", function (event) {

                                            leafac.setTippy({
                                              event,
                                              element: this,
                                              tippyProps: {
                                                trigger: "click",
                                                content: "To join an existing course you either have to follow an invitation link or be invited via email. Contact your course staff for more information.",
                                              },
                                            });
                                          });

/********************************************************************************/

leafac.execute.functions.set("dfzadgbvpgypff", function (event, $$0, $$1) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            touch: false,
                            content: $$0,
                          },
                        });

                        leafac.setTippy({
                          event,
                          element: this,
                          elementProperty: "dropdown",
                          tippyProps: {
                            trigger: "click",
                            interactive: true,
                            content: $$1,  
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("kwuqkzkhgwnfd", function (event) {

              if (
                event?.detail?.previousLocation?.origin !== window.location.origin ||
                event?.detail?.previousLocation?.pathname !== window.location.pathname ||
                event?.detail?.previousLocation?.search !== window.location.search
              )
                this.scroll(0, 0);
            });

/********************************************************************************/

leafac.execute.functions.set("qoynnnqxqpeyz", function (event, $$0) {

                  leafac.setTippy({
                    event,
                    element: this,
                    elementProperty: "dropdown",
                    tippyProps: {
                      trigger: "click",
                      interactive: true,
                      content: $$0,  
                    },
                  });
                });

/********************************************************************************/

leafac.execute.functions.set("higtjgtkqelahz", function (event, $$0, $$1, $$2) {

                  leafac.setTippy({
                    event,
                    element: this,
                    elementProperty: "dropdown",
                    tippyProps: {
                      trigger: "click",
                      interactive: true,
                      content: $$0,
                    },
                  });

                  if ($$1)
                    this.onclick = async () => {
                      this.classList.remove("strong");
                      this.classList.remove("text--green");
                      await fetch($$2, {
                        method: "PATCH",
                        headers: { "CSRF-Protection": "true", },
                        cache: "no-store",
                      });
                    };
                });

/********************************************************************************/

leafac.execute.functions.set("fbalyzfiaivaxo", function (event, $$0) {

                        leafac.setTippy({
                          event,
                          element: this,
                          elementProperty: "dropdown",
                          tippyProps: {
                            trigger: "click",
                            interactive: true,
                            content: $$0,
                          },
                        });
                      });

/********************************************************************************/

leafac.execute.functions.set("hhzhxrhkvszqzf", function (event) {

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        tippyProps: {
                                          touch: false,
                                          content: "Current Courselore version",
                                        },
                                      });
                                    });

/********************************************************************************/

leafac.execute.functions.set("uieeewhvaeak", function (event) {

                                      leafac.setTippy({
                                        event,
                                        element: this,
                                        tippyProps: {
                                          touch: false,
                                          content: "Latest Courselore version",
                                        },
                                      });
                                    });

/********************************************************************************/

leafac.execute.functions.set("cgcilkkehvukfz", function (event) {

            leafac.setTippy({
              event,
              element: this,
              tippyProps: {
                touch: false,
                content: "Loading…",
              },
            });

            window.onlivenavigate = () => {
              const parentElement = this;
              parentElement.hidden = false;
              const element = parentElement.querySelector("div");
              let width = 5;
              window.clearTimeout(element.updateTimeout);
              (function update() {
                if (parentElement.hidden || !leafac.isConnected(element)) return;
                element.style.width = width.toString() + "%";
                width += (95 - width) / (20 + Math.random() * 15);
                element.updateTimeout = window.setTimeout(update, 200 + Math.random() * 300);
              })();
            };

            window.onlivenavigateerror = () => {
              this.hidden = true;
            };
          });

/********************************************************************************/

leafac.execute.functions.set("fypjycvudynjzs", function (event, $$0) {

                      leafac.setTippy({
                        event,
                        element: this,
                        elementProperty: "dropdown",
                        tippyProps: {
                          trigger: "click",
                          interactive: true,
                          content: $$0,
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("eldgejeerlegmx", function (event, $$0, $$1, $$2) {

                  this.onclick = () => {
                    const content = this.closest("[data-content-source]").getAttribute("data-content-source");
                    const newMessage = document.querySelector('[key="new-message"]');
                    newMessage.querySelector('[key="content-editor--button--write"]')?.click();
                    const element = newMessage.querySelector('[key="content-editor--write--textarea"]');
                    textFieldEdit.wrapSelection(
                      element,
                      ((element.selectionStart > 0) ? "\n\n" : "") + "> " + $$0 + "#" + $$1 + "/" + $$2 + "\n>\n> " + content.replaceAll("\n", "\n> ") + "\n\n",
                      ""
                    );
                    element.focus();
                    tippy.hideAll();
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("cyaxlmqkldmthd", function (event, $$0, $$1) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            trigger: "click",
                            interactive: true,
                            onHidden: () => { this.onmouseleave(); },
                            content: $$0,
                          },
                        });

                        window.clearTimeout(this.tooltipContentTimeout);
                        this.tooltipContentSkipLoading = false;
                        
                        this.onmouseenter = this.onfocus = async () => {
                          window.clearTimeout(this.tooltipContentTimeout);
                          if (this.tooltipContentSkipLoading) return;
                          this.tooltipContentSkipLoading = true;
                          leafac.loadPartial(this.tooltip.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                          this.tooltip.props.content.querySelector('[key="loading"]').hidden = true;
                          this.tooltip.props.content.querySelector('[key="content"]').hidden = false;
                          this.tooltip.setProps({});
                        };
                        
                        this.onmouseleave = this.onblur = () => {
                          window.clearTimeout(this.tooltipContentTimeout);
                          if (this.matches(":hover, :focus-within") || this.tooltip.state.isShown) return;
                          this.tooltipContentTimeout = window.setTimeout(() => {
                            this.tooltip.props.content.querySelector('[key="loading"]').hidden = false;
                            this.tooltip.props.content.querySelector('[key="content"]').hidden = true;
                            this.tooltipContentSkipLoading = false;
                          }, 60 * 1000);
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("dvrisunlgxwtth", function (event, $$0) {

                  leafac.setTippy({
                    event,
                    element: this,
                    elementProperty: "copied",
                    tippyProps: {
                      theme: "green",
                      trigger: "manual",
                      content: "Copied",
                    },
                  });

                  this.onclick = async () => {
                    await navigator.clipboard.writeText($$0);
                    this.copied.show();
                    await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                    this.copied.hide();
                  };
                });

/********************************************************************************/

leafac.execute.functions.set("dklrdlooqpeszl", function (event, $$0) {

                        this.onmouseenter = this.onfocus = async () => {
                          const messageEdit = this.closest('[key^="message/"]').querySelector('[key="message--edit"]');
                          const messageEditForm = messageEdit.querySelector('[key="form"]');
                          if (messageEditForm.skipLoading === true) return;
                          messageEditForm.skipLoading = true;
                          leafac.loadPartial(messageEditForm, await (await fetch($$0, { cache: "no-store" })).text());
                          messageEdit.querySelector('[key="loading"]').hidden = true;
                          messageEdit.querySelector('[key="form"]').hidden = false;
                          autosize.update(this.closest('[key^="message/"]')?.querySelector('[key="message--edit"] [key="content-editor--write--textarea"]'));
                        };

                        this.onclick = () => {
                          this.closest('[key^="message/"]').querySelector('[key="message--show"]').hidden = true;
                          this.closest('[key^="message/"]').querySelector('[key="message--edit"]').hidden = false;
                          autosize.update(this.closest('[key^="message/"]').querySelector('[key="message--edit"] [key="content-editor--write--textarea"]'));
                          tippy.hideAll();
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("giuzswqgycvakj", function (event, $$0, $$1) {

                        leafac.setTippy({
                          event,
                          element: this,
                          tippyProps: {
                            trigger: "click",
                            interactive: true,
                            onHidden: () => { this.onmouseleave(); },
                            content: $$0,
                          },
                        });

                        window.clearTimeout(this.tooltipContentTimeout);
                        this.tooltipContentSkipLoading = false;

                        this.onmouseenter = this.onfocus = async () => {
                          window.clearTimeout(this.tooltipContentTimeout);
                          if (this.tooltipContentSkipLoading) return;
                          this.tooltipContentSkipLoading = true;
                          leafac.loadPartial(this.tooltip.props.content.querySelector('[key="content"]'), await (await fetch($$1, { cache: "no-store" })).text());
                          this.tooltip.props.content.querySelector('[key="loading"]').hidden = true;
                          this.tooltip.props.content.querySelector('[key="content"]').hidden = false;
                          this.tooltip.setProps({});
                        };

                        this.onmouseleave = this.onblur = () => {
                          window.clearTimeout(this.tooltipContentTimeout);
                          if (this.matches(":hover, :focus-within") || this.tooltip.state.isShown) return;
                          this.tooltipContentTimeout = window.setTimeout(() => {
                            this.tooltip.props.content.querySelector('[key="loading"]').hidden = false;
                            this.tooltip.props.content.querySelector('[key="content"]').hidden = true;
                            this.tooltipContentSkipLoading = false;
                          }, 60 * 1000);
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("psvvjvvimzgbr", function (event, $$0) {

                          leafac.setTippy({
                            event,
                            element: this,
                            elementProperty: "dropdown",
                            tippyProps: {
                              theme: "rose",
                              trigger: "click",
                              interactive: true,
                              content: $$0,  
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("cxgapmycafabdw", function (event, $$0) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        touch: false,
                        content: $$0,
                      },
                    });

                    const textarea = this.closest("form").querySelector('[key="content-editor--write--textarea"]');

                    (textarea.mousetrap ??= new Mousetrap(textarea)).bind("mod+enter", () => { this.click(); return false; });                                  
                  });

/********************************************************************************/

leafac.execute.functions.set("btzbudshvpzvgr", function (event) {

                    this.onclick = () => {
                      this.closest('[key^="message/"]').querySelector('[key="message--show"]').hidden = false;
                      this.closest('[key^="message/"]').querySelector('[key="message--edit"]').hidden = true;

                      const messageEdit = this.closest('[key^="message/"]').querySelector('[key="message--edit"]');
                      messageEdit.querySelector('[key="loading"]').hidden = false;
                      messageEdit.querySelector('[key="form"]').hidden = true;
                      messageEdit.querySelector('[key="form"]').skipLoading = false;
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("hkukksaegmmhlf", function (event) {

                          leafac.relativizeDateTimeElement(this, { capitalize: true });
                        });

/********************************************************************************/

leafac.execute.functions.set("cvliezdzbtbocr", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Online",
                        },
                      });
                    });

/********************************************************************************/

leafac.execute.functions.set("bgzcbvbynutepb", function (event) {

                  leafac.setTippy({
                    event,
                    element: this,
                    tippyProps: {
                      touch: false,
                      content: "Course Staff",
                    },
                  });
                });

/********************************************************************************/

leafac.execute.functions.set("pgxzmipiutgsw", function (event, $$0) {

          leafac.setTippy({
            event,
            element: this,
            tippyProps: {
              interactive: true,
              appendTo: document.querySelector("body"),
              delay: [1000, null],
              touch: ["hold", 1000],
              content: $$0,  
            },
          });
        });

/********************************************************************************/

leafac.execute.functions.set("fixgnebnaykheh", function (event, $$0) {

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      tippyProps: {
                                        touch: false,
                                        content: "Copy Email",
                                      },
                                    });

                                    leafac.setTippy({
                                      event,
                                      element: this,
                                      elementProperty: "copied",
                                      tippyProps: {
                                        theme: "green",
                                        trigger: "manual",
                                        content: "Copied",
                                      },
                                    });

                                    this.onclick = async () => {
                                      await navigator.clipboard.writeText($$0);
                                      this.copied.show();
                                      await new Promise((resolve) => { window.setTimeout(resolve, 1000); });
                                      this.copied.hide();
                                    };
                                  });

/********************************************************************************/

leafac.execute.functions.set("hfwybvdquqrale", function (event) {

                                      leafac.relativizeDateTimeElement(this, { preposition: "on", target: this.parentElement });
                                    });

/********************************************************************************/

leafac.execute.functions.set("bdiypnbetmedhr", function (event) {

          leafac.setTippy({
            event,
            element: this,
            tippyProps: {
              touch: false,
              content: "Anonymous to Other Students",
            },
          });
        });

/********************************************************************************/

leafac.execute.functions.set("epcssncdycxbjm", function (event) {

                  this.dragLevel = 0;
                  this.ondragenter = (event) => {
                    if (!event.dataTransfer.types.includes("Files")) return;
                    event.preventDefault();
                    if (this.dragLevel === 0) this.classList.add("drag");
                    this.dragLevel++;
                  };
                  this.ondragleave = (event) => {
                    if (!event.dataTransfer.types.includes("Files")) return;
                    event.preventDefault();
                    this.dragLevel--;
                    if (this.dragLevel === 0) this.classList.remove("drag");
                  };
                  this.ondragover = (event) => {
                    if (!event.dataTransfer.types.includes("Files")) return;
                    event.preventDefault();
                  };
                  this.ondrop = (event) => {
                    if (!event.dataTransfer.types.includes("Files")) return;
                    event.preventDefault();
                    this.classList.remove("drag");
                    this.dragLevel = 0;
                    const fileList = [...event.dataTransfer.items].flatMap((item) => item.webkitGetAsEntry()?.isFile ?? true ? [item.getAsFile()] : []);
                    if (fileList.length > 0)
                      this.querySelector('[key="avatar-chooser--upload"]').upload(fileList);
                  };

                  this.onbeforemorph = (event) => !event?.detail?.liveUpdate;
                });

/********************************************************************************/

leafac.execute.functions.set("hboxfjqxqpyfji", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Add Avatar",
                        },
                      });

                      this.onclick = () => {
                        this.closest("form").querySelector('[key="avatar-chooser--upload"]').click();
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("eejwmcnwmyrrgj", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          touch: false,
                          content: "Update Avatar",
                        },
                      });

                      this.onclick = () => {
                        this.closest("form").querySelector('[key="avatar-chooser--upload"]').click();
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("fswslmlqyyyth", function (event) {

                      leafac.setTippy({
                        event,
                        element: this,
                        tippyProps: {
                          theme: "rose",
                          touch: false,
                          content: "Remove Avatar",
                        },
                      });
                      
                      this.onclick = () => {
                        const form = this.closest("form");
                        const avatar = form.querySelector('[name="avatar"]')
                        avatar.value = "";
                        form.querySelector('[key="avatar-chooser--empty"]').hidden = false;
                        form.querySelector('[key="avatar-chooser--filled"]').hidden = true;
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("wvqxzhwuncjux", function (event, $$0, $$1) {

                    this.isModified = false;

                    const avatarChooser = this.closest('[key="avatar-chooser"]');
                    const avatar = avatarChooser.querySelector('[name="avatar"]');
                    const avatarEmpty = avatarChooser.querySelector('[key="avatar-chooser--empty"]');
                    const avatarFilled = avatarChooser.querySelector('[key="avatar-chooser--filled"]');

                    leafac.setTippy({
                      event,
                      element: avatarChooser,
                      elementProperty: "uploadingIndicator",
                      tippyProps: {
                        trigger: "manual",
                        hideOnClick: false,
                        content: $$0,
                      },
                    });

                    this.upload = async (fileList) => {
                      const body = new FormData();
                      body.append("avatar", fileList[0]);
                      this.value = "";
                      tippy.hideAll();
                      avatarChooser.uploadingIndicator.show();
                      const response = await fetch($$1, {
                        method: "POST",
                        headers: { "CSRF-Protection": "true", },
                        cache: "no-store",
                        body,
                      });
                      avatarChooser.uploadingIndicator.hide();
                      if (!response.ok) {
                        leafac.setTippy({
                          element: avatarChooser,
                          elementProperty: "uploadingError",
                          tippyProps: {
                            theme: "rose",
                            trigger: "manual",
                            content: await response.text(),
                          },
                        });
                        avatarChooser.uploadingError.show();
                        return;
                      }
                      const avatarURL = await response.text();
                      avatar.value = avatarURL;
                      avatarEmpty.hidden = true;
                      avatarFilled.hidden = false;
                      avatarFilled.querySelector("img").setAttribute("src", avatarURL);
                    };

                    this.onchange = () => {
                      this.upload(this.files);
                    };
                  });

/********************************************************************************/

leafac.execute.functions.set("ceqraednhaojns", function (event) {

                        this.onvalidate = () => {
                          if (!leafac.isModified(this))
                            return "Please provide the email address to which you’d like to update.";
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("sgkuubrrvxpgh", function (event) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              trigger: "click",
                              content: "You must confirm your password because this is an important operation that affects your account.",
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("btbddaoktloydh", function (event) {

                        this.onvalidate = () => {
                          if (this.value !== this.closest("form").querySelector('[name="newPassword"]').value)
                            return "New Password & New Password Confirmation don’t match.";
                        };
                      });

/********************************************************************************/

leafac.execute.functions.set("fqmffyedwppxuj", function (event) {

                      this.onchange = () => {
                        if (this.checked) {
                          this.closest("form").querySelector('[name="isEmailNotificationsForMentions"]').checked = true;
                          this.closest("form").querySelector('[name="isEmailNotificationsForMessagesInConversationsInWhichYouParticipated"]').checked = true;
                          this.closest("form").querySelector('[name="isEmailNotificationsForMessagesInConversationsYouStarted"]').checked = true;
                        }
                        for (const element of this.closest("form").querySelectorAll('[name="emailNotificationsForAllMessages"]')) {
                          element.disabled = !this.checked;
                          element.closest("label").classList[this.checked ? "remove" : "add"]("disabled");
                        }
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("gqzkuxyhivpwpr", function (event) {

                      this.onchange = () => {
                        if (!this.checked) {
                          const element = this.closest("form").querySelector('[name="isEmailNotificationsForAllMessages"]');
                          element.checked = false;
                          element.onchange();
                        }
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("gecqgxqajdeech", function (event) {

                      this.onchange = () => {
                        if (!this.checked) {
                          const element = this.closest("form").querySelector('[name="isEmailNotificationsForAllMessages"]');
                          element.checked = false;
                          element.onchange();
                        }
                        if (this.checked) this.closest("form").querySelector('[name="isEmailNotificationsForMessagesInConversationsYouStarted"]').checked = true;
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("gydomzxwjwbjkh", function (event) {

                      this.onchange = () => {
                        if (!this.checked) {
                          const element = this.closest("form").querySelector('[name="isEmailNotificationsForAllMessages"]');
                          element.checked = false;
                          element.onchange();
                        }
                        if (!this.checked) this.closest("form").querySelector('[name="isEmailNotificationsForMessagesInConversationsInWhichYouParticipated"]').checked = false;
                      };
                    });

/********************************************************************************/

leafac.execute.functions.set("cypkltevryuiqn", function (event) {

                    leafac.setTippy({
                      event,
                      element: this,
                      tippyProps: {
                        content: "You always receive email notifications for course staff announcements.",
                      },
                    });
                  });

/********************************************************************************/

leafac.execute.functions.set("bhcwuipqzysfgl", function (event) {

                          leafac.setTippy({
                            event,
                            element: this,
                            tippyProps: {
                              trigger: "click",
                              content: "You must confirm your email because this is an important operation that affects your account.",
                            },
                          });
                        });

/********************************************************************************/

leafac.execute.functions.set("efkrhudqtuboju", function (event, $$0) {

                        leafac.setTippy({
                          event,
                          element: this,
                          elementProperty: "dropdown",
                          tippyProps: {
                            theme: "rose",
                            trigger: "click",
                            interactive: true,
                            content: $$0,  
                          },
                        });
                      });

